import React from "react";
import cx from "classnames";

import styles from "../../styles/SearchIcon.module.less";

type SearchIconProps = {
  isActive?: boolean;
  onClick?: () => void;
  className?: string;
};

const SearchIcon = ({ isActive, onClick, className }: SearchIconProps) => {
  return (
    <button
      className={cx(styles.icon, isActive && styles.active, className)}
      type="button"
      onClick={onClick}
    >
      <div className={styles.lineMain} />
      <div className={styles.lineClose} />
      <div className={styles.circle} />
    </button>
  );
};

export default SearchIcon;
