import React, { forwardRef, ReactNode } from "react";
import cx from "classnames";

import LinkButton, { LinkButtonProps } from "common/components/LinkButton";
import Spinner from "common/components/Spinner";

import styles from "../../styles/Button.module.less";

/** General appearance of the button:
 * - `primary`: tracks the primary app color
 * - `secondary`: tracks app control styles
 */
export type ButtonVariant = "primary" | "secondary";

export type ButtonProps = LinkButtonProps & {
  variant?: ButtonVariant;
  /**
   * How much space in the UI the button should take up.
   */
  size?: "xs" | "s" | "m";
  isLoading?: boolean;
  /** inline content to act as the label */
  children: ReactNode;
};

/** Block interactive button or link styled as a button, which fills its parent. */
const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant = "primary",
      isLoading = false,
      size = "m",
      children,
      className,
      disabled,
      ...rest
    }: ButtonProps,
    ref,
  ) => {
    return (
      <LinkButton
        ref={ref}
        {...rest}
        // Loading and Disabled will have different styles, but will be treated the same functionally
        disabled={disabled || isLoading}
        className={cx(
          styles.button,
          styles[`variant-${variant}`],
          styles[`size-${size}`],
          isLoading && styles.isLoading,
          className,
        )}
      >
        <Spinner className={styles.spinner} />
        <span className={styles.label}>{children}</span>
      </LinkButton>
    );
  },
);

export default Button;
