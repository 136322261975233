// eslint-disable-next-line no-restricted-imports
import {
  DefaultGenerics,
  Outlet,
  PartialGenerics,
  ReactLocation,
  Router as LibRouter,
  useLocation,
  useRouter,
} from "@tanstack/react-location";
import { parse, stringify } from "query-string";
import React, { ComponentType, ReactNode, useEffect, useRef } from "react";
import * as Sentry from "@sentry/react";

import { Routes } from "./types";

interface ErrorBoundaryFallbackProps {
  children: ReactNode;
  initialKey: string | undefined;
  onReset: () => void;
}

const ErrorBoundaryFallback = ({ children, onReset, initialKey }: ErrorBoundaryFallbackProps) => {
  const location = useLocation();
  const router = useRouter();

  useEffect(() => {
    if (location.current.key !== initialKey && !router.pending) {
      onReset();
    }
  }, [location.current.key, initialKey, location, router.pending, onReset]);
  return <>{children}</>;
};

interface RouteErrorBoundaryProps {
  ErrorComponent: ComponentType;
  children: ReactNode;
}

const RouteErrorBoundary = ({ ErrorComponent, children }: RouteErrorBoundaryProps) => {
  const location = useLocation();
  // ref defined outside of fallback to avoid re-renders of function child bashing this value
  const initialKeyRef = useRef(location.current.key);

  return (
    <Sentry.ErrorBoundary
      key="error"
      fallback={({ resetError }) => (
        <ErrorBoundaryFallback
          onReset={() => {
            initialKeyRef.current = location.current.key;
            resetError();
          }}
          initialKey={initialKeyRef.current}
        >
          <ErrorComponent />
        </ErrorBoundaryFallback>
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

const location = new ReactLocation({
  parseSearch: parse,
  stringifySearch: (search) => {
    const searchStr = stringify(search, { arrayFormat: "comma" });
    return searchStr && `?${searchStr}`;
  },
});

type RouterProps<TPath extends PartialGenerics = DefaultGenerics> = {
  routes: Routes<TPath>;
  ErrorComponent: ComponentType;
};

/**
 * A Router for an app. This will generally be added last in the main react tree
 * to conditionally include/render sub-pages based on route state.
 *
 * @see [Router](https://react-location.tanstack.com/docs/api#router)
 *   for library documentation
 */
const Router = ({ routes, ErrorComponent }: RouterProps) => {
  return (
    <LibRouter
      location={location}
      // this will catch any errors when loading the route itself
      // (eg. loading async data)
      defaultErrorElement={<ErrorComponent />}
      routes={[
        {
          element: (
            // this will catch any errors when actually rendering the component
            // (eg in the body of a component in the rendered react-tree for a route)
            <RouteErrorBoundary ErrorComponent={ErrorComponent}>
              <Outlet />
            </RouteErrorBoundary>
          ),
          children: routes,
        },
      ]}
    />
  );
};

export default Router;
