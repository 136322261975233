/**
 * Event names that partners will use to trigger our ecommerce events in their
 * Google Tag Manager setup. Ensure these names are kept consistent with the documentation
 * we've shared with them here:
 * https://docs.google.com/document/d/1sYGNv_llIEIvoFTq2Z9fX12oGn3BT46NgAlDYM0LsI0/
 */
export enum EcommerceEventName {
  Purchase = "purchase",
  AddToCart = "add_to_cart",
  RemoveFromCart = "remove_from_cart",
  ViewItem = "view_item",
  SelectItem = "select_item",
  ViewItemList = "view_item_list",
  BeginCheckout = "begin_checkout",
}

/** At least one of item_id and item_name is required */
type RequiredItemInfo =
  | {
      /**
       * Item or ProductLine id, depending on context
       * ProductLine will have a pl_ prefix */
      item_id: string;
    }
  | {
      /** ProductLine name + Product name */
      item_name: string;
    };

export type EcommerceItem = RequiredItemInfo & {
  /** ProductLine Name */
  item_brand?: string;
  item_category?: string;
  /** Product Name | Item Name */
  item_variant?: string;
  /** per unit price of the item in dollars */
  price?: number;
  discount?: number;
  quantity?: number;
};

export type EcommerceItemWithQuantity = EcommerceItem & { quantity: number };

/**
 * A generic type for an Ecommerce event we send to GA4
 * Includes an *eventName* that corresponds to one of the events found at
 * https://developers.google.com/analytics/devguides/collection/ga4/reference/events
 * and the related *data* we will send alongwith it
 */
export type EcommerceEventType<T extends EcommerceEventName, E extends object> = {
  eventName: T;
  data: E;
};

export type EcommercePurchaseEvent = EcommerceEventType<
  EcommerceEventName.Purchase,
  {
    currency: "USD";
    /** Some unique identifier to avoid duplicate purchase events */
    transaction_id: string;
    /** Dollar value of the transaction */
    value: number;
    shipping: number;
    tax: number;
    items: EcommerceItem[];
    coupon?: string;
  }
>;

export type EcommerceAddToCartEvent = EcommerceEventType<
  EcommerceEventName.AddToCart,
  {
    items: EcommerceItemWithQuantity[];
  }
>;

export type EcommerceRemoveFromCartEvent = EcommerceEventType<
  EcommerceEventName.RemoveFromCart,
  {
    items: EcommerceItemWithQuantity[];
  }
>;

export type EcommerceViewItemEvent = EcommerceEventType<
  EcommerceEventName.ViewItem,
  {
    items: EcommerceItem[];
  }
>;

export type EcommerceSelectItemEvent = EcommerceEventType<
  EcommerceEventName.SelectItem,
  {
    /* The name of the list the item is viewed in */
    item_list_name: string;
    items: EcommerceItem[];
  }
>;

export type EcommerceViewItemListEvent = EcommerceEventType<
  EcommerceEventName.ViewItemList,
  {
    /* The name of the list the item is viewed in */
    item_list_name: string;
    items: EcommerceItem[];
  }
>;

export type EcommerceBeginCheckoutEvent = EcommerceEventType<
  EcommerceEventName.BeginCheckout,
  {
    items: EcommerceItemWithQuantity[];
  }
>;

export type EcommerceEvent =
  | EcommercePurchaseEvent
  | EcommerceAddToCartEvent
  | EcommerceRemoveFromCartEvent
  | EcommerceViewItemEvent
  | EcommerceSelectItemEvent
  | EcommerceViewItemListEvent
  | EcommerceBeginCheckoutEvent;
