/*
Setting this here allows us to dynamically set
the webpack public path. This is important because we
are generating chunked assets that if you define a publicPath
in the webpack config, will try to serve from that publicPath/<asset> name

This is fine for production, but causes issues in pre-production environments
that we need to validate a production bundle through.

Letting us dynamically set the public path allows us to set it per environment
and will let us serve all assets from one spot.

We only need to set this on production builds
*/

if (process.env.NODE_ENV === "production") {
  __webpack_public_path__ = `${STATIC_URL}dist/`;
}
