import { lazy } from "common/load/componentUtils";

import { CloseProp, useDrawer } from "client/common/hooks/stackables";
import { useClinic } from "client/clinics/hooks";

import type { FulfillerInfoDrawerProps } from "./FulfillerInfoDrawer";
import { hasFulfillerInfoDrawer } from "./utils";

const FulfillerInfoDrawer = lazy(() => import("./FulfillerInfoDrawer"));
const ShippingInfoDrawer = lazy(() => import("./ShippingInfoDrawer"));

export const useFulfillerInfoDrawer = () => {
  const { open } = useDrawer();
  return {
    open: (props: FulfillerInfoDrawerProps & CloseProp) => open(FulfillerInfoDrawer, props),
  };
};

export const usePrimaryFulfillerInfoDrawer = () => {
  const { open } = useFulfillerInfoDrawer();
  const { primary_vendor_slug: slug } = useClinic();

  return {
    open: (props: CloseProp = {}) => open({ ...props, slug }),
    hasDrawer: hasFulfillerInfoDrawer(slug),
  };
};

export const useShippingInfoDrawer = () => {
  const { open } = useDrawer();
  return {
    open: () => open(ShippingInfoDrawer),
  };
};
