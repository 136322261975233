import { CardPaymentMethod, PaymentProvider, WalletType } from "common/types";
import { Optional } from "common/utils/types";

import noImage from "../images/no-image.svg";
import mastercard from "../images/payment-providers/mastercard.svg";
import visa from "../images/payment-providers/visa.svg";
import amex from "../images/payment-providers/amex.svg";
import discover from "../images/payment-providers/discover.svg";
import applepay from "../images/payment-providers/applepay.svg";
import googlepay from "../images/payment-providers/googlepay.svg";

// s3 buckets
const production = "pharmacy-production";
const staging = "pharmacy-staging";
const demo = "pharmacy-demo";
const pr = "pharmacy-pr-test";
const sandbox = "pharmacy-sandbox";

/**
 * Fallback to a default image if none is set.
 * Replace non production images with ones from the production bucket across various environments.
 *
 * Should track logic for BE function `image_smart_src` at common.utils.media_utils
 *
 * Note that some newly uploaded images may not render during local dev, as they'll only exist
 * in staging but this will convert those urls to production
 */
export const smartSrc = (src: Optional<string>) => {
  return (src || noImage)
    .replace(staging, production)
    .replace(demo, production)
    .replace(pr, production)
    .replace(sandbox, production);
};

export const getPaymentProviderImageSrc = (method: CardPaymentMethod) => {
  const provider = method.wallet ? method.wallet : method.brand;

  switch (provider) {
    case PaymentProvider.MASTERCARD:
      return mastercard;
    case PaymentProvider.VISA:
      return visa;
    case PaymentProvider.AMEX:
      return amex;
    case PaymentProvider.DISCOVER:
      return discover;
    case WalletType.APPLE:
      return applepay;
    case WalletType.GOOGLE:
      return googlepay;
    default:
      return undefined;
  }
};
