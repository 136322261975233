import React from "react";

export type LoadStatusContextValue = {
  /**
   * If the currently loaded application is considered stale or not. If the application
   * is considered stale, any in-app navigation will result in a hard re-load instead of
   * using the routing library.
   */
  isStale: boolean;
  /**
   * Callback to force the application to be considered stale.
   * Useful for some exceptional (and critical) cases with where we
   * want to get the user to reload the app when convienient.
   */
  forceIsStale: () => void;
  /**
   * Callback to trigger if the application requires a manual reload or not. If the application
   * requires a manual reload (via this callback, or if the app gets exceedingly stale) the user
   * will not be able to do anything and an overlay will be displayed which asks the user to refresh
   * the page.
   */
  onRequiresManualReload: () => void;
  /** If a manual reload is required or not. */
  requiresManualReload: boolean;
};

/**
 * Interface to track the current application loading status
 *
 * @see {@link load/hooks!useLoadStatus} for how to consume in general application code.
 */
const LoadStatusContext = React.createContext<LoadStatusContextValue>({
  isStale: false,
  requiresManualReload: false,
  onRequiresManualReload: () => console.warn("onRequiresManualRefresh is not bound"),
  forceIsStale: () => console.warn("forceIsStale is not bound"),
});

export default LoadStatusContext;
