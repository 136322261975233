import React from "react";
import cx from "classnames";
import { Collapse as ReactCollapse, UnmountClosed, CollapseProps } from "react-collapse";

import styles from "../styles/components/Collapse.module.less";

const getTheme = (className?: string) => ({
  collapse: styles.collapse,
  content: cx(styles.content, className),
});

/**
 * Use in most circumstances. Will keep the content mounted for better accessibility.
 */
const Collapse = ({ className, ...other }: CollapseProps) => {
  return <ReactCollapse theme={getTheme(className)} {...other} />;
};

/**
 * Use when the content should be unmounted when closed.
 * This is useful for performance when the content is large or we don't want to trigger focus
 */
const CollapseUnmountClosed = ({ className, ...other }: CollapseProps) => {
  return <UnmountClosed theme={getTheme(className)} {...other} />;
};

export default Object.assign(Collapse, { UnmountClosed: CollapseUnmountClosed });
