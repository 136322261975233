import { CommonMapProps } from "common/components/Map";
import { IPaginator } from "common/types";

export interface IFact {
  id: number;
  icon: string;
  value: string;
}

/** Matches catalog.api.serializers.BasicVideoSerializer */
export interface IVideo {
  id: number;
  video_url: string;
  thumbnail_url: string;
}

export interface SearchResponse<T> {
  paginator: IPaginator;
  results: T[];
}

export type MapProps = CommonMapProps & {
  /** Whether to show a standard marker or a paw icon */
  markerStyle?: "default" | "paw";
};

// Enums

export enum ModalStatus {
  Open = "1",
  Closed = "0",
}

/** Representation of an image used for in-app display */
export type MediaImage = {
  key: string;
  kind: "image";
  thumbnailUrl: string;
  contentUrl: string;
  alt: string;
};

/** Representation of a video used for in-app display */
export type MediaVideo = {
  key: string;
  kind: "video";
  previewImageUrl: string;
  videoUrl: string;
  alt: string;
};

/** Possible Media types to display generically */
export type MediaItem = MediaImage | MediaVideo;
