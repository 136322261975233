import { useIntercom } from "react-use-intercom";
import { useCallback } from "react";

import { useFetchQuery } from "common/queries/hooks";

import { useClinic } from "client/clinics/hooks";

import { IListSupportItem, ListSupportItemsSearch, IListSupportSection } from "../types";
import { keys, urls } from "./constants";

export const useSupportSectionsQuery = () => {
  return useFetchQuery<IListSupportSection[]>(keys.sectionList(), {
    fetch: { url: urls.sections() },
  });
};

export const useSupportItemsQuery = (
  search: ListSupportItemsSearch,
  { enabled = true }: { enabled?: boolean } = {},
) => {
  return useFetchQuery<IListSupportItem[], ListSupportItemsSearch>(keys.itemList(search), {
    fetch: { url: urls.items(), body: search },
    config: { keepPreviousData: true, enabled },
  });
};

export const useFeaturedSupportItemsQuery = () => {
  return useFetchQuery<IListSupportItem[]>(keys.featuredItemsList(), {
    fetch: { url: urls.featuredItems() },
  });
};

export const useSupportChat = () => {
  const clinic = useClinic();
  const intercom = useIntercom();

  const updateCurrentClinic = useCallback(() => {
    intercom.update({
      customAttributes: {
        "Current Clinic ID": clinic.id,
        "Current Clinic Name": clinic.internal_name,
      },
    });
  }, [intercom, clinic.id, clinic.internal_name]);

  const show = useCallback(() => {
    updateCurrentClinic();
    intercom.show();
  }, [intercom, updateCurrentClinic]);

  const showNewMessage = useCallback(() => {
    updateCurrentClinic();
    intercom.showNewMessage();
  }, [intercom, updateCurrentClinic]);

  return { show, showNewMessage };
};
