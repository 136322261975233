import { PathsDefinition, UrlOpts, Urls, UrlDefinitions, UrlsMeta } from "../types";

type VoidSafe<T extends {} | void> = T extends void ? {} : T;

/**
 * Builds urls which are a simple function that take Destination fields as well as route params.
 * The idea is to create more type-safe building blocks to use with functions like `useNavigate`
 * or the `Link` component.
 *
 * The factory method to build the path (`to`) is also exposed via a `to` property on the outputted url
 */
export const makeUrls = <TPath extends PathsDefinition | void = never>(
  base: string,
  definitions: UrlDefinitions<TPath extends void ? {} : TPath>,
): Urls<VoidSafe<TPath>> => {
  const urls: any = Object.fromEntries(
    Object.entries(definitions).map(([k, v]: [string, any]) => {
      const path = base + v.path;
      const toFn = (...params: any) => (v.to ? base + v.to(...params) : path);

      const url = (opts: UrlOpts<any, any>) => {
        const dest: any = { ...opts, to: toFn(opts?.params) };
        delete dest.params;
        return dest;
      };
      url.to = toFn;
      url.path = path;
      return [k, url];
    }),
  );
  urls.path = `${base}/*`;
  return urls as unknown as Urls<VoidSafe<TPath>>;
};

/**
 * Like `makeUrls` but used for non-leaf nodes in the routing hierarcy (eg. the root `urls`).
 *
 * Appends appropritate meta-data to a collection of child urls.
 * */
export const makeParentUrls = <TChildren extends Record<string, Urls<{}>>>(
  base: string,
  children: TChildren,
): TChildren & UrlsMeta => ({ ...children, path: `${base}/*` });

/**
 * A helper function to prefix our base path with a required URL prefix
 *
 * This is helpful because we need to route requests to specific URLs
 * based on the environment we are in
 */
export const makeBasePath = (basePath: string): string => {
  if (typeof window === "undefined" || !window.URL_PREFIX) {
    return basePath;
  }
  // url prefix is always assumed to end with a `/`, but our urls are absolute
  // from the prefix, so we need to remove the trailing `/` from the prefix.
  return `${window.URL_PREFIX.slice(0, -1)}${basePath}`;
};

/**
 * Builds a cross app, fully qualified url so that we can link
 * the clinic to the client app.
 *
 * This is needed because we might need to clean the `URL_PREFIX` if it's there
 * in both the clinic's `base_client_url` as well as the url we want to link to
 * and can potentially simplify some shared logic we eventually need.
 */
export const makeClientUrl = (baseClientUrl: string | undefined, url: string): string => {
  if (!baseClientUrl) {
    return "";
  }

  if (
    window.URL_PREFIX &&
    baseClientUrl.endsWith(window.URL_PREFIX) &&
    url.startsWith(window.URL_PREFIX)
  ) {
    return baseClientUrl + url.replace(window.URL_PREFIX, "");
  }

  return baseClientUrl + url;
};
