import React from "react";

import Navigate from "common/router/Navigate";
import { MakePaths } from "common/router/types";
import { eagerRoute, makeRoutes, MakeRoutesOpts } from "common/router/utils/routes";
import { makeUrls } from "common/router/utils/urls";

import { CartId, CheckoutErrorSearch, KlarnaCheckoutSearch, CheckoutStep } from "../types";

const paths = {
  start: "/:cartId",
  detail: "/:cartId/:step",
  confirmation: "/confirmed",
  error: "/error",
};

type StartParams = {
  cartId: CartId;
};

type CheckoutParams = {
  cartId: CartId;
  step: CheckoutStep;
};

type CheckoutPathDefinitions = {
  start: { Params: StartParams };
  detail: { Params: CheckoutParams; Search: KlarnaCheckoutSearch };
  confirmation: {};
  error: { Search: CheckoutErrorSearch };
};

export type CheckoutPaths = MakePaths<CheckoutPathDefinitions>;

export const useCheckoutRoutes = (opts: MakeRoutesOpts) => {
  return makeRoutes({ ...opts }, [
    eagerRoute({ path: "/", element: <Navigate dest={{ to: "./active" }} /> }),
    { path: paths.detail, element: () => import("../components/Checkout") },
    { path: paths.confirmation, element: () => import("../components/OrderConfirmationPage") },
    { path: paths.error, element: () => import("../components/CheckoutErrorPage") },
    { path: paths.start, element: () => import("../components/CheckoutRoot") },
  ]);
};

export const makeCheckoutUrls = (base: string) => {
  return makeUrls<CheckoutPathDefinitions>(base, {
    start: { path: paths.start, to: ({ cartId }) => `/${cartId}` },
    detail: { path: paths.detail, to: ({ cartId, step }) => `/${cartId}/${step}` },
    confirmation: { path: paths.confirmation },
    error: { path: paths.error },
  });
};
