export const UTM_PARAMETERS_LIST: string[] = [
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_content",
  "utm_term",
];

export const UTM_PARAM_TO_CAMPAIGN_JOURNEY_FIELD: Record<string, keyof CampaignJourneyCreate> = {
  utm_source: "source",
  utm_medium: "medium",
  utm_campaign: "campaign_slug",
  utm_content: "content",
  utm_term: "term",
};

/** matches CampaignJourneyValidationSerializer */
export type CampaignJourneyCreate = {
  source: string;
  medium: string;
  campaign_slug: string;
  content: string;
  term: string;
};
