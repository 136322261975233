import React, { useState } from "react";
import cx from "classnames";

import MenuDrawer from "./MenuDrawer";

import styles from "../../styles/MenuDrawerIconButton.module.less";

type MenuDrawerIconButtonProps = {
  isWhite?: boolean;
  className?: string;
};

const MenuDrawerIconButton = ({ isWhite = false, className = "" }: MenuDrawerIconButtonProps) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  return (
    <>
      <button
        className={cx(styles.button, isWhite && styles.white)}
        type="button"
        onClick={() => setIsMenuVisible((v) => !v)}
      >
        <span className={cx(styles.line, className, styles.line1)} />
        <span className={cx(styles.line, className, styles.line2)} />
        <span className={cx(styles.line, className, styles.line3)} />
      </button>
      <MenuDrawer visible={isMenuVisible} onClose={() => setIsMenuVisible(false)} />
    </>
  );
};

export default MenuDrawerIconButton;
