import React from "react";

import { urls } from "client/routes";

import Page from "../layouts/Page";
import Body from "../layouts/Body";
import Button from "../buttons/Button";
import ErrorContainer, { ErrorContainerProps } from "./ErrorContainer";

import styles from "../../styles/ErrorPage.module.less";

type ErrorPageProps = ErrorContainerProps & {
  buttonText?: string;
  buttonLink?: string;
  refresh?: boolean;
  hasRefreshButton?: boolean;
  PageComponent?: React.ComponentType;
};

const ErrorPage = (props: ErrorPageProps) => {
  const {
    buttonText = "Go to Homepage",
    buttonLink = urls.home.root.to(),
    hasRefreshButton = false,
    refresh = false,
    PageComponent = Page,
    ...errorContainerProps
  } = props;

  return (
    <PageComponent>
      <Body className={styles.body}>
        <ErrorContainer {...errorContainerProps} />
        <div className={styles.content}>
          <div className={styles.actions}>
            {hasRefreshButton && (
              <Button
                variant="secondary"
                onClick={() => window.location.reload()}
                className={styles.action}
              >
                Refresh Page
              </Button>
            )}
            <Button
              {...(refresh
                ? { onClick: () => window.location.assign(buttonLink) }
                : { dest: { to: buttonLink } })}
              className={styles.action}
            >
              {buttonText}
            </Button>
          </div>
        </div>
      </Body>
    </PageComponent>
  );
};

export default ErrorPage;
