/** Matches vendors constants VendorSlugs */
export enum VendorSlug {
  Wedgewood = "WEDGEWOOD",
  Truepill = "TRUEPILL",
  RoyalCanin = "ROYAL_CANIN",
  Hills = "HILLS",
  HillsDiet = "HILLS_CGP",
  Purina = "PURINA",
  ExpressScripts = "EXPRESS_SCRIPTS",
  BlueBuffalo = "BLUE_BUFFALO",
  IVet = "IVET",
  Vetsource = "VETSOURCE",
}
