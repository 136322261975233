import { lazy } from "common/load/componentUtils";

import { CloseProp, useDialog, useDrawer } from "client/common/hooks/stackables";

import type { SubscriptionFrequencyUpdateDrawerProps } from "../components/drawers/SubscriptionFrequencyUpdateDrawer";
import type { SubscriptionQuantityUpdateDrawerProps } from "../components/drawers/SubscriptionQuantityUpdateDrawer";
import type { SubscriptionAddressUpdateDrawerProps } from "../components/drawers/SubscriptionAddressUpdateDrawer";
import type { SubscriptionPauseDialogProps } from "../components/dialogs/SubscriptionPauseDialog";
import type { SubscriptionResumeDrawerProps } from "../components/drawers/SubscriptionResumeDrawer";
import type { SubscriptionCancelDialogProps } from "../components/dialogs/SubscriptionCancelDialog";
import type { SubscriptionSkipNextOrderDialogProps } from "../components/dialogs/SubscriptionSkipNextOrderDialog";
import type { SubscriptionPaymentUpdateDrawerProps } from "../components/drawers/SubscriptionPaymentUpdateDrawer";
import type { SubscriptionNextOrderDateUpdateDrawerProps } from "../components/drawers/SubscriptionNextOrderDateUpdateDrawer";
import type { SubscriptionPaymentUpdateConfirmationDialogProps } from "../components/dialogs/SubscriptionPaymentUpdateConfirmationDialog";
import type { SubscriptionsBulkUpdateAddressDrawerProps } from "../components/drawers/SubscriptionsBulkUpdateAddressDrawer";
import type { SubscriptionBulkUpdatePaymentMethodDrawerProps } from "../components/drawers/SubscriptionBulkUpdatePaymentMethodDrawer";

const SubscriptionFrequencyUpdateDrawer = lazy(
  () => import("../components/drawers/SubscriptionFrequencyUpdateDrawer"),
);
const SubscriptionQuantityUpdateDrawer = lazy(
  () => import("../components/drawers/SubscriptionQuantityUpdateDrawer"),
);
const SubscriptionAddressUpdateDrawer = lazy(
  () => import("../components/drawers/SubscriptionAddressUpdateDrawer"),
);
const SubscriptionResumeDrawer = lazy(
  () => import("../components/drawers/SubscriptionResumeDrawer"),
);
const SubscriptionPaymentUpdateDrawer = lazy(
  () => import("../components/drawers/SubscriptionPaymentUpdateDrawer"),
);
const SubscriptionNextOrderDateUpdateDrawer = lazy(
  () => import("../components/drawers/SubscriptionNextOrderDateUpdateDrawer"),
);
const SubscriptionPaymentUpdateConfirmationDialog = lazy(
  () => import("../components/dialogs/SubscriptionPaymentUpdateConfirmationDialog"),
);
const SubscriptionInfoDrawer = lazy(() => import("../components/drawers/SubscriptionInfoDrawer"));

const SubscriptionsBulkUpdateAddressDrawer = lazy(
  () => import("../components/drawers/SubscriptionsBulkUpdateAddressDrawer"),
);
const SubscriptionPauseDialog = lazy(() => import("../components/dialogs/SubscriptionPauseDialog"));
const SubscriptionCancelDialog = lazy(
  () => import("../components/dialogs/SubscriptionCancelDialog"),
);
const SubscriptionSkipNextOrderDialog = lazy(
  () => import("../components/dialogs/SubscriptionSkipNextOrderDialog"),
);
const SubscriptionBulkUpdatePaymentMethodDrawer = lazy(
  () => import("../components/drawers/SubscriptionBulkUpdatePaymentMethodDrawer"),
);

export const useSubscriptionFrequencyUpdateDrawer = () => {
  const { open } = useDrawer();
  return {
    open: (props: SubscriptionFrequencyUpdateDrawerProps & CloseProp) =>
      open(SubscriptionFrequencyUpdateDrawer, props),
  };
};

export const useSubscriptionQuantityUpdateDrawer = () => {
  const { open } = useDrawer();
  return {
    open: (props: SubscriptionQuantityUpdateDrawerProps & CloseProp) =>
      open(SubscriptionQuantityUpdateDrawer, props),
  };
};

export const useSubscriptionAddressUpdateDrawer = () => {
  const { open } = useDrawer();
  return {
    open: (props: SubscriptionAddressUpdateDrawerProps & CloseProp) =>
      open(SubscriptionAddressUpdateDrawer, props),
  };
};

export const useSubscriptionResumeDrawer = () => {
  const { open } = useDrawer();
  return {
    open: (props: SubscriptionResumeDrawerProps & CloseProp) =>
      open(SubscriptionResumeDrawer, props),
  };
};

export const useSubscriptionPaymentUpdateDrawer = () => {
  const { open } = useDrawer();
  return {
    open: (props: SubscriptionPaymentUpdateDrawerProps & CloseProp) =>
      open(SubscriptionPaymentUpdateDrawer, props),
  };
};

export const useSubscriptionNextOrderDateUpdateDrawer = () => {
  const { open } = useDrawer();
  return {
    open: (props: SubscriptionNextOrderDateUpdateDrawerProps & CloseProp) =>
      open(SubscriptionNextOrderDateUpdateDrawer, props),
  };
};

export const useSubscriptionInfoDrawer = () => {
  const { open } = useDrawer();
  return {
    open: () => open(SubscriptionInfoDrawer),
  };
};

export const useSubscriptionsBulkUpdateAddressDrawer = () => {
  const { open } = useDrawer();
  return {
    open: (props: SubscriptionsBulkUpdateAddressDrawerProps & CloseProp) =>
      open(SubscriptionsBulkUpdateAddressDrawer, props),
  };
};

export const useSubscriptionBulkUpdatePaymentMethodDrawer = () => {
  const { open } = useDrawer();
  return {
    open: (props: SubscriptionBulkUpdatePaymentMethodDrawerProps & CloseProp) =>
      open(SubscriptionBulkUpdatePaymentMethodDrawer, props),
  };
};

export const useSubscriptionPauseDialog = () => {
  const { open } = useDialog();
  return {
    open: (props: SubscriptionPauseDialogProps & CloseProp) => open(SubscriptionPauseDialog, props),
  };
};

export const useSubscriptionCancelDialog = () => {
  const { open } = useDialog();
  return {
    open: (props: SubscriptionCancelDialogProps & CloseProp) =>
      open(SubscriptionCancelDialog, props),
  };
};

export const useSubscriptionSkipNextOrderDialog = () => {
  const { open } = useDialog();
  return {
    open: (props: SubscriptionSkipNextOrderDialogProps & CloseProp) =>
      open(SubscriptionSkipNextOrderDialog, props),
  };
};

export const useSubscriptionPaymentUpdateConfirmationDialog = () => {
  const { open } = useDialog();
  return {
    open: (props: SubscriptionPaymentUpdateConfirmationDialogProps & CloseProp) =>
      open(SubscriptionPaymentUpdateConfirmationDialog, props),
  };
};
