import React from "react";

import { eagerRoute, makeRoutes, MakeRoutesOpts } from "common/router/utils/routes";
import { MakePaths } from "common/router/types";
import { makeParentUrls, makeUrls } from "common/router/utils/urls";
import Navigate from "common/router/Navigate";

import {
  OnboardingSubscriptionDetailParams,
  OnboardingSubscriptionsErrorSearch,
  OnboradingSubscriptionListParams,
  OnboradingSubscriptionsSearch,
} from "../types";

type OnboardingSubscriptionPathDefinitions = {
  start: { Search: OnboradingSubscriptionsSearch };
  list: { Params: OnboradingSubscriptionListParams; Search: OnboradingSubscriptionsSearch };
  detail: { Params: OnboardingSubscriptionDetailParams; Search: OnboradingSubscriptionsSearch };
  error: { Search: OnboardingSubscriptionsErrorSearch };
};

export type OnboardingPaths = { subscriptions: MakePaths<OnboardingSubscriptionPathDefinitions> };

const DefaultOnboardingElement = () => {
  return <Navigate dest={{ to: "./subscriptions" }} />;
};

export const useOnboardingRoutes = (opts: MakeRoutesOpts) => {
  return makeRoutes({ ...opts, NotFoundComponent: DefaultOnboardingElement }, [
    eagerRoute({
      path: "/subscriptions",
      children: makeRoutes(opts, [
        {
          path: "/",
          element: () => import("../components/OnboardingSubscriptionsStartPage"),
        },
        {
          path: "/error",
          element: () => import("../components/OnboardingSubscriptionsErrorPage"),
        },
        {
          path: "/:id/:step",
          element: () => import("../components/OnboardingDetailSubscriptionPage"),
        },
        {
          path: "/:step",
          element: () => import("../components/OnboardingSubscriptionsPage"),
        },
      ]),
    }),
  ]);
};

const makeSubscriptionUrls = (base: string) => {
  return makeUrls<OnboardingSubscriptionPathDefinitions>(base, {
    start: { path: "/" },
    list: { path: "/:step", to: ({ step }) => `/${step}` },
    detail: { path: "/:id/:step", to: ({ id, step }) => `/${id}/${step}` },
    error: { path: "/error" },
  });
};

export const makeOnboardingUrls = (base: string) => {
  return makeParentUrls(base, {
    subscriptions: makeSubscriptionUrls(`${base}/subscriptions`),
  });
};
