import React from "react";
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";

import Icon from "./Icon";

import styles from "../styles/components/Spinner.module.less";

type SpinnerProps = { className?: string };

const Spinner = ({ className = "" }: SpinnerProps) => {
  return (
    <Icon fixedWidth={true} icon={faSpinnerThird} className={`${styles.spinner} ${className}`} />
  );
};

export default Spinner;
