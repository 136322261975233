import { MakePaths } from "common/router/types";
import { makeRoutes, MakeRoutesOpts } from "common/router/utils/routes";
import { makeUrls } from "common/router/utils/urls";

const paths = {
  list: "/",
};

type PrescriptionPathDefinitions = {
  list: {};
};

export type PrescriptionPaths = MakePaths<PrescriptionPathDefinitions>;

export const usePrescriptionRoutes = (opts: MakeRoutesOpts) => {
  return makeRoutes(opts, [
    { path: paths.list, element: () => import("../components/PrescriptionsPage") },
  ]);
};

export const makePrescriptionUrls = (base: string) => {
  return makeUrls<PrescriptionPathDefinitions>(base, {
    list: { path: paths.list },
  });
};
