import { useCallback, useContext, useEffect, useRef } from "react";

import { useAuthentication } from "common/auth/hooks";
import { useLoadStatus } from "common/load/hooks";
import { makeBasePath } from "common/router/utils/urls";

import FetchContext, { FetchContextValue } from "./FetchContext";
import { FakeFetchOpts, FetchRequestOpts, RequestBody } from "./types";
import { FetchError } from "./errors";

/**
 * Primary hook to send HTTP requests.
 *
 * Consumes {@link fetch/FetchContext!FetchContext}, and plugin the current
 * {@link auth/AuthenticationContext!AuthenticationContext}. Authentication context may change
 * for different app sub-trees (eg. pharmacy client refills and pharmacy client checkout).
 *
 * This should generally be used for all HTTP requests in the app.
 *
 * @see {@link useFakeFetch} for a mock version of this request for developing new features
 * if the BE isn't quite ready, or for testing out new UIs
 */
export const useFetch = <TResponseBody = void, TRequestBody extends RequestBody = {}>() => {
  const { onUnauthenticated } = useAuthentication();
  const { onRequiresManualReload: onRequiresManualRefresh } = useLoadStatus();
  const { fetch } = useContext<FetchContextValue<TResponseBody, TRequestBody>>(FetchContext);

  // save callbacks in a ref so that if they change they don't trigger a change in fetch, which
  // could trigger consumers to re-send http requests, but we always get the latest callback
  // if the respective callback is called in `fetch`
  const callbacksRef = useRef<{
    onUnauthenticated: (error: FetchError) => void;
    onRequiresManualRefresh: (error: FetchError) => void;
  }>({ onUnauthenticated, onRequiresManualRefresh });

  useEffect(() => {
    callbacksRef.current = { onUnauthenticated, onRequiresManualRefresh };
  }, [onUnauthenticated, onRequiresManualRefresh]);

  return useCallback(
    (opts: FetchRequestOpts<TRequestBody>) =>
      fetch({
        ...opts,
        url: makeBasePath(opts.url),
        onUnauthorized: (error) => callbacksRef.current.onUnauthenticated(error),
        onNeedsRefresh: (error) => callbacksRef.current.onRequiresManualRefresh(error),
      }),
    [fetch],
  );
};

/**
 * Hook to mirror {@link useFetch} but pluggable to define your own mock response / errors
 */
export const useFakeFetch = <TResponseBody = void, TRequestBody extends RequestBody = {}>() => {
  const { onUnauthenticated } = useAuthentication();
  const { onRequiresManualReload: onRequiresManualRefresh } = useLoadStatus();
  const { fakeFetch } = useContext<FetchContextValue<TResponseBody, TRequestBody>>(FetchContext);

  // save callbacks in a ref so that if they change they don't trigger a change in fetch, which
  // could trigger consumers to re-send http requests, but we always get the latest callback
  // if the respective callback is called in `fakeFetch`
  const callbacksRef = useRef<{
    onUnauthenticated: (error: FetchError) => void;
    onRequiresManualRefresh: (error: FetchError) => void;
  }>({ onUnauthenticated, onRequiresManualRefresh });

  useEffect(() => {
    callbacksRef.current = { onUnauthenticated, onRequiresManualRefresh };
  }, [onUnauthenticated, onRequiresManualRefresh]);

  return useCallback(
    (
      opts: FetchRequestOpts<TRequestBody>,
      genResponse: (body: TRequestBody | undefined) => TResponseBody,
      fakeFetchOpts: FakeFetchOpts,
    ) =>
      fakeFetch(
        {
          ...opts,
          onUnauthorized: (error) => callbacksRef.current.onUnauthenticated(error),
          onNeedsRefresh: (error) => callbacksRef.current.onRequiresManualRefresh(error),
        },
        genResponse,
        fakeFetchOpts,
      ),
    [fakeFetch],
  );
};

/**
 * Hook to mirror {@link useFetch} but for interfacing with the Turbo app
 */
export const useTurboFetch = <TResponseBody = void, TRequestBody extends RequestBody = {}>() => {
  const { fetch } = useContext<FetchContextValue<TResponseBody, TRequestBody>>(FetchContext);

  return useCallback(
    (opts: FetchRequestOpts<TRequestBody>) =>
      fetch({
        ...opts,
        url: window.TURBO_HOST + opts.url,
        // TODO: handle authentication
        onUnauthorized: () => {},
        onNeedsRefresh: () => {},
      }),
    [fetch],
  );
};
