import { makeRoutes, MakeRoutesOpts } from "common/router/utils/routes";
import { MakePaths } from "common/router/types";
import { makeUrls } from "common/router/utils/urls";

import { MarketingEmailUnsubscribeSearch } from "../types";

const paths = {
  unsubscribe: "/unsubscribe",
};

type CommunicationPathDefinitions = {
  unsubscribe: { Search: MarketingEmailUnsubscribeSearch };
};

export type CommunicationPaths = MakePaths<CommunicationPathDefinitions>;

export const useCommunicationRoutes = (opts: MakeRoutesOpts) => {
  return makeRoutes(opts, [
    {
      path: paths.unsubscribe,
      element: () => import("../components/UnsubscribePage"),
    },
  ]);
};

export const makeCommunicationUrls = (base: string) => {
  return makeUrls<CommunicationPathDefinitions>(base, {
    unsubscribe: { path: paths.unsubscribe },
  });
};
