import React, { ReactNode } from "react";

import FetchProvider from "common/fetch/FetchProvider";

const csrfToken = window.CSRF_TOKEN;

type ClinicFetchProviderProps = {
  children: ReactNode;
};

const ClientFetchProvider = ({ children }: ClinicFetchProviderProps) => {
  return <FetchProvider csrfToken={csrfToken}>{children}</FetchProvider>;
};

export default ClientFetchProvider;
