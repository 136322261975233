import React, { ReactNode } from "react";

import AnalyticsProvider from "common/analytics/AnalyticsProvider";

import { getInitialClientData } from "../utils";

const { user } = getInitialClientData();

type ClientAnalyticsProviderProps = {
  children: ReactNode;
};

/**
 * A top-level provider for analytics related to the client app.
 */
const ClientAnalyticsProvider = ({ children }: ClientAnalyticsProviderProps) => {
  const {
    integration_settings: integrationSettings,
    has_internal_analytics_tracking: hasTracking,
  } = getInitialClientData();

  return (
    <AnalyticsProvider
      user={user}
      hasSessionRecording={integrationSettings.analytics__client__has_session_recording}
      hasTracking={hasTracking}
    >
      {children}
    </AnalyticsProvider>
  );
};

export default ClientAnalyticsProvider;
