import React from "react";
import * as Sentry from "@sentry/react";

import GlobalErrorPage from "./GlobalErrorPage";

type GlobalErrorBoundaryProps = { children: React.ReactNode };

/**
 * This should be the first and last thing rendered in the react tree as a final catch-all for any errors.
 */
const GlobalErrorBoundary = ({ children }: GlobalErrorBoundaryProps) => {
  return <Sentry.ErrorBoundary fallback={GlobalErrorPage}>{children}</Sentry.ErrorBoundary>;
};

export default GlobalErrorBoundary;
