import React, { forwardRef } from "react";
// This is meant to be consumed by the rest of the app, and is a controlled
// eslint-disable-next-line no-restricted-imports
import { Link as LibLink, LinkPropsType, useLoadRoute, useRouter } from "@tanstack/react-location";

import { Destination, PluckDestination } from "common/router/types";
import { Dialog } from "common/stackable/types";
import { useHoverIntent } from "common/hooks/hover";
import { noop } from "common/utils/noop";
import { useLoadStatus } from "common/load/hooks";
import { useNavigate } from "common/router/hooks";

export type CommonNodeProps = {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  onFocus?: (e: React.FocusEvent<HTMLElement, Element>) => void;
  onMouseEnter?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onMouseLeave?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onMouseDown?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onMouseUp?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => void;
  tabIndex?: number;
  role?: string;
  style?: React.CSSProperties;
};

export type LinkNodeProps = {
  dest: Destination;
  newTab?: boolean;
  dialog?: never;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  href?: never;
  type?: never;
  onPreload?: never;
};

export type DialogLinkNodeProps = {
  dialog: Dialog;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  dest?: never;
  href?: never;
  type?: never;
  onPreload?: never;
};

export type LinkButtonNodeProps = {
  href: string;
  newTab?: boolean;
  download?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  type?: never;
  dest?: never;
  dialog?: never;
  onPreload?: never;
};

export type ButtonNodeProps = {
  id?: string;
  type?: "button" | "submit" | "reset";
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onPreload?: () => void;
  href?: never;
  dest?: never;
  dialog?: never;
  // require onClick if type doesn't imply that it is already handled
} & (
  | { type: "submit" | "reset" }
  | { onClick: (event: React.MouseEvent<HTMLButtonElement>) => void }
);

export type StaticNodeProps = {
  dest?: never;
  dialog?: never;
  onPreload?: never;
  onClick?: never;
  href?: never;
  type?: never;
};

export type NodeProps = LinkButtonNodeProps | LinkNodeProps | DialogLinkNodeProps | ButtonNodeProps;

export type LinkButtonProps = CommonNodeProps & NodeProps;

/** Resolves if node is a link. Useful for type-narrowing */
const isLink = (props: NodeProps): props is LinkNodeProps => "dest" in props;

/** Resolves if node is a dialog. Useful for type-narrowing */
const isDialog = (props: NodeProps): props is DialogLinkNodeProps => "dialog" in props;

/** Resolves if node is a button. Useful for type-narrowing */
const isButton = (props: NodeProps): props is ButtonNodeProps =>
  "onClick" in props || "type" in props;

/** Resolves if node is an external link. Useful for type-narrowing */
const isExternalLink = (props: NodeProps): props is LinkButtonNodeProps => "href" in props;

type LinkProps = PluckDestination<LinkPropsType> & { dest: Destination; newTab?: boolean };

/**
 * Component to be used generally to link to other pages is our Single page web app.
 *
 * Also will perform a hard redirect if the current load state is considered stale.
 *
 * @see {@link load/LoadStatusProvider!LoadStatusProvider} for more information on
 * how load status is determined.
 *
 * @see [Link](https://react-location.tanstack.com/docs/api#link) for library documentation
 *
 */
const Link = forwardRef((props: LinkProps, ref: React.Ref<HTMLAnchorElement>) => {
  const { dest, onClick = noop, newTab, preload, ...rest } = props;

  const loadRoute = useLoadRoute();
  const router = useRouter();
  const { isStale } = useLoadStatus();
  const navigate = useNavigate();

  const { getReferenceProps } = useHoverIntent<HTMLAnchorElement>({
    onHover: () => loadRoute(dest, { maxAge: router.defaultLinkPreloadMaxAge }),
  });

  // programmatically navigate if the resource is stale
  // to force a hard refresh
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (isStale && !rest.disabled) {
      e.preventDefault();
      e.stopPropagation();
      navigate(dest, { replace: rest.replace });
    }
    onClick(e);
  };

  return (
    <LibLink
      _ref={ref}
      {...dest}
      {...getReferenceProps(rest)}
      {...(newTab ? { target: "_blank", rel: "noopener noreferrer" } : {})}
      preload={undefined} // Handled by useLinkPreloading
      onClick={handleClick}
    />
  );
});

type ExternalProps = {
  newTab?: boolean;
} & React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>;

/**
 * A link generally for external pages, but may be used to link internally and open a second tab/window
 * or to download internal resources.
 * */
const ExternalLink = forwardRef((props: ExternalProps, ref: React.Ref<HTMLAnchorElement>) => {
  const { children, newTab = true, ...rest } = props;
  return (
    <a {...rest} {...(newTab ? { target: "_blank" } : {})} rel="noopener noreferrer" ref={ref}>
      {children}
    </a>
  );
});

type PreloadButtonProps = {
  onPreload?: () => void;
} & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

/**
 * Component used for buttons to allow for preloading
 */
const PreloadButton = forwardRef((props: PreloadButtonProps, ref: React.Ref<HTMLButtonElement>) => {
  const { onPreload = noop, type = "button", ...rest } = props;

  const { getReferenceProps } = useHoverIntent<HTMLButtonElement>({
    onHover: onPreload,
  });

  // ESLint cant see that the type has a TS type set making this safely either a valid "button" or "submit"
  // eslint-disable-next-line react/button-has-type
  return <button ref={ref} {...getReferenceProps(rest)} type={type} />;
});

/**
 * Component used when the dialog prop is passed to the LinkButton component.
 */
type DialogButtonProps = {
  dialog: Dialog;
} & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

const DialogButton = forwardRef((props: DialogButtonProps, ref: React.Ref<HTMLButtonElement>) => {
  const { dialog, onClick, type = "button", ...rest } = props;

  const { getReferenceProps } = useHoverIntent<HTMLButtonElement>({
    onHover: () => dialog.loader(),
  });

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    dialog.open();
    onClick?.(e);
  };

  // ESLint cant see that the type has a TS type set making this safely either a valid "button" or "submit"
  // eslint-disable-next-line react/button-has-type
  return <button ref={ref} {...getReferenceProps(rest)} onClick={handleClick} type={type} />;
});

/**
 * Resolves to the appropriate underlying component for navigating/performing actions
 * on user clicks.
 *
 * This component does not define it's own styling: any styling should be applied via `className`
 * */
const LinkButton = forwardRef(
  (props: LinkButtonProps, ref: React.Ref<HTMLButtonElement | HTMLAnchorElement>) => {
    const { disabled, children } = props;

    // Disabled link buttons should just be buttons to avoid navigation
    if (isExternalLink(props) && !disabled) {
      return (
        <ExternalLink {...props} ref={ref as React.Ref<HTMLAnchorElement>}>
          {children}
        </ExternalLink>
      );
    }

    // Disabled link buttons should just be buttons to avoid navigation
    if (isLink(props) && !disabled) {
      return (
        <Link {...props} ref={ref as React.Ref<HTMLAnchorElement>}>
          {children}
        </Link>
      );
    }

    if (isDialog(props)) {
      return (
        <DialogButton {...props} ref={ref as React.Ref<HTMLButtonElement>}>
          {children}
        </DialogButton>
      );
    }

    if (isButton(props)) {
      return (
        <PreloadButton {...props} ref={ref as React.Ref<HTMLButtonElement>}>
          {children}
        </PreloadButton>
      );
    }

    // Fallback for when the button is disabled, hence the noop
    return (
      <button {...props} onClick={noop} type="button" ref={ref as React.Ref<HTMLButtonElement>}>
        {children}
      </button>
    );
  },
);

export default LinkButton;
