import React, { Suspense } from "react";

export type LazyComponent<P> = () => Promise<{ default: React.ComponentType<P> }>;

/**  Factory for making lazy loading HOCs  */
export const makeLazyHOC = (fallback: React.ReactNode) => (component: LazyComponent<any>) => {
  const LazyComponent = React.lazy(component);

  return (props: any) => (
    <Suspense fallback={fallback || null}>
      <LazyComponent {...props} />
    </Suspense>
  );
};

/** HOC for lazy loading components without any fallback styles */
export const lazy = makeLazyHOC(null);
