import { MakePaths } from "common/router/types";
import { makeRoutes, MakeRoutesOpts } from "common/router/utils/routes";
import { makeUrls } from "common/router/utils/urls";

import { HelpCenterSearch } from "../types";

const paths = {
  helpCenter: "/",
  privacyPolicy: "/privacy-policy",
  termsOfUse: "/terms-of-use",
};

type SupportPathDefinitions = {
  helpCenter: { Search: HelpCenterSearch };
  privacyPolicy: {};
  termsOfUse: {};
};

export type SupportPaths = MakePaths<SupportPathDefinitions>;

export const useSupportRoutes = (opts: MakeRoutesOpts) => {
  return makeRoutes(opts, [
    { path: paths.helpCenter, element: () => import("../components/SupportPage") },
    { path: paths.privacyPolicy, element: () => import("../components/PrivacyPolicyPage") },
    { path: paths.termsOfUse, element: () => import("../components/TermsOfUsePage") },
  ]);
};

export const makeSupportUrls = (base: string) => {
  return makeUrls<SupportPathDefinitions>(base, {
    helpCenter: { path: paths.helpCenter },
    privacyPolicy: { path: paths.privacyPolicy },
    termsOfUse: { path: paths.termsOfUse },
  });
};
