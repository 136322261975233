import React, { ReactNode, useState } from "react";
import {
  QueryCache,
  QueryClient,
  QueryClientProvider as LibQueryClientProvider,
} from "@tanstack/react-query";

export type QueryClientProviderProps = {
  children?: ReactNode;
  queryCache?: QueryCache;
};

/**
 * Query client provider which should generally appear in an apps component
 * tree before using react-query features.
 *
 * @see {@link queries/hooks!useFetchQuery} and {@link queries/hooks!useFetchMutation}
 *   for how to consume in general application code.
 * */
const QueryClientProvider = ({ children, queryCache }: QueryClientProviderProps) => {
  /**
   * queryClient for application queries and mutations, all application consumers should access the query client
   * through `useQueryClient` and not reference this variable directly.
   */
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            // Queries will only be stale and refetched if marked as invalidated manually or after this duration in MS (5 minutes)
            staleTime: 5 * 1000 * 60,
            // Queries will no longer be cached (and will then be garbage collected) after this duration in MS (10 minutes)
            cacheTime: 10 * 1000 * 60,
            // Don't retry automatically
            retry: false,
            // Don't refetch when focusing the window
            refetchOnWindowFocus: false,
          },
        },
        queryCache,
      }),
  );

  return <LibQueryClientProvider client={queryClient}>{children}</LibQueryClientProvider>;
};

export default QueryClientProvider;
