import { MakePaths } from "common/router/types";
import { makeRoutes, MakeRoutesOpts } from "common/router/utils/routes";
import { makeUrls } from "common/router/utils/urls";
import { ReviewRating } from "common/types";

import { ModalStatus } from "client/common/types";

import { OrderSearch } from "../types";

const paths = {
  list: "/",
  detail: "/:id",
  payment: "/:id/payment",
};

type OrderDetailParams = {
  id: number;
};

type OrderPaymentParams = {
  id: number;
};

type OrdersSearch = {
  reviewCreateModalIsVisible?: ModalStatus;
  productLineId?: number;
  initialRating?: ReviewRating;
  modificationsModalIsVisible?: ModalStatus;
  orderId?: number;
  cancelationModalIsVisible?: ModalStatus;
};

type OrderPathdefinitions = {
  list: { Search: OrdersSearch };
  detail: { Params: OrderDetailParams; Search: OrderSearch };
  payment: { Params: OrderPaymentParams };
};

export type OrderPaths = MakePaths<OrderPathdefinitions>;

export const useOrderRoutes = (opts: MakeRoutesOpts) => {
  return makeRoutes(opts, [
    { path: paths.list, element: () => import("../components/OrdersPage") },
    { path: paths.payment, element: () => import("../components/OrderPaymentPage") },
    { path: paths.detail, element: () => import("../components/OrderPage") },
  ]);
};

export const makeOrderUrls = (base: string) => {
  return makeUrls<OrderPathdefinitions>(base, {
    list: { path: paths.list },
    detail: { path: paths.detail, to: ({ id }) => `/${id}` },
    payment: { path: paths.payment, to: ({ id }) => `/${id}/payment` },
  });
};
