import { CartCode } from "../types";

/** private urls for queries and mutations for the `carts` feature folder */
export const urls = {
  cart: (id: number | CartCode) => `/api/client/v1/carts/${id}/`,
  cartDelivery: (id: number) => `/api/client/v1/carts/${id}/delivery/`,
  cartItems: () => `/api/client/v1/carts/cartitems/`,
  cartItem: (id: number, version: string = "v1") => `/api/client/${version}/carts/cartitems/${id}/`,

  cartItemSubscription: (cartItemId: number) =>
    `/api/client/v1/carts/cartitems/${cartItemId}/subscription/`,

  cartItemAssignment: (cartId: number) => `/api/client/v1/carts/${cartId}/assignments/`,

  cartPromoCodes: (cartId: number) => `/api/client/v1/carts/${cartId}/promocodes/`,
  cartPromoCode: (cartId: number, promoId: number) =>
    `/api/client/v1/carts/${cartId}/promocodes/${promoId}/`,
  cartTax: (cartId: number) => `/api/client/v1/carts/${cartId}/tax/`,
  promoCodesApplyAll: () => `/api/client/v1/carts/promo-codes/apply-all/`,
  cartRefillTooSoonSummary: (cartId: number) =>
    `/api/client/v1/carts/${cartId}/refill-too-soon-summary/`,
  controlledSubstancesSummary: () => `/api/client/v1/carts/controlled-substances-summary/`,
};

/** private query keys for queries for the `carts` feature folder */
export const keys = {
  /** all queries in this namespace */
  all: () => ["carts-all"],

  // carts
  carts: () => [...keys.all(), "carts"],
  // details
  cartDetails: () => [...keys.carts(), "detail"],
  cartDetail: (id: number) => [...keys.cartDetails(), id],
  activeCartDetail: () => [...keys.cartDetails(), CartCode.ACTIVE],
  // refill too soon summary
  cartRefillTooSoonSummaries: () => [...keys.carts(), "refill-too-soon-summary"],
  cartRefillTooSoonSummary: (cartId: number) => [...keys.cartRefillTooSoonSummaries(), cartId],
  // tax
  cartTaxes: () => [...keys.all(), "tax"],
  cartTax: (id: number) => [...keys.cartTaxes(), id],

  // Mutations
  mutations: () => [...keys.all(), "mutations"],
  mutateCarts: () => [...keys.mutations(), "mutate-cart"],
  mutateCartItems: () => [...keys.mutations(), "mutate-cart-item"],
};
