import { ListSupportItemsSearch } from "../types";

export const urls = {
  sections: () => "/api/client/v1/docs/support-sections/",
  items: () => "/api/client/v1/docs/support-items/",
  featuredItems: () => "/api/client/v1/docs/support-items/featured/",
};

export const keys = {
  all: () => ["support-all"],
  // sections
  sections: () => [...keys.all(), "sections"],
  sectionLists: () => [...keys.sections(), "list"],
  sectionList: () => [...keys.sectionLists(), {}],
  // items
  items: () => [...keys.all(), "items"],
  itemLists: () => [...keys.items(), "list"],
  itemList: (search: ListSupportItemsSearch) => [...keys.itemLists(), search],
  // featured items
  featuredItemsList: () => [...keys.items(), "featured"],
};
