/* eslint-disable no-underscore-dangle */

import React, { ComponentType } from "react";
// eslint-disable-next-line no-restricted-imports
import { DefaultGenerics, Route } from "@tanstack/react-location";

import { Optional } from "common/utils/types";

import {
  PartialGenerics,
  EagerRoute,
  LazyRoute,
  MadeRoute,
  RouteDefinition,
  Routes,
} from "../types";

/**
 * Decorates a normal route with infra needed to handle lazy loading.
 *
 * This should be the go-to function for defining any given route
 */
const lazyRoute = <TPaths extends PartialGenerics = DefaultGenerics>(
  route: LazyRoute<TPaths>,
): Route<TPaths> => {
  return {
    ...route,
    element: async () => {
      const module = await route.element();
      return <module.default />;
    },
  };
};

export interface MakeRoutesOpts {
  /**
   * The fallback route to fallback to. This can also be explicitly unset
   * which is useful in the case where there are nested routes
   * which may redundantly handle the not found case.
   */
  NotFoundComponent: Optional<ComponentType>;
}

const resolveRoute = <TPaths extends PartialGenerics = DefaultGenerics>(
  route: RouteDefinition<TPaths>,
) => {
  if (!route.element) {
    return route;
  }
  if ((route as EagerRoute<TPaths>).__eager__) {
    return route;
  }
  return lazyRoute(route as LazyRoute<TPaths>);
};

/**
 * Given an array of routes, decorates the routes with some reasonable defaults:
 *
 * 1. We always assume a route is lazy by default or contains children.
 * 2. Ensures that a set of routes always provides a not found fallback component
 *
 * Also tags returned route with `__made__` so that we can detect if this factory
 * method was used to construct routes by the infrastructure.
 */
export const makeRoutes = <TPath extends PartialGenerics = DefaultGenerics>(
  { NotFoundComponent }: MakeRoutesOpts,
  routes: RouteDefinition<TPath>[],
): Routes<TPath> => {
  const madeRoutes: Route<TPath>[] & { __made__?: true } = [
    ...routes.map(resolveRoute),
    ...(NotFoundComponent ? [{ path: "/*", element: <NotFoundComponent /> }] : []),
  ];
  // type casting is just to be able to add a property to an array
  madeRoutes.__made__ = true;
  return madeRoutes as MadeRoute<TPath>[] & { __made__: true };
};

/**
 * An escape-hatch for the rare case you actually want a route to loaded immediately and not
 * code-split.
 *
 * This may be particularly helpful for any routes that decorate the UI (eg. a top-level route).
 */
export const eagerRoute = <TPath extends PartialGenerics = DefaultGenerics>(
  route: MadeRoute<TPath>,
): EagerRoute<TPath> => {
  return { ...route, __eager__: true };
};
