import { MakePaths } from "common/router/types";
import { makeRoutes, MakeRoutesOpts } from "common/router/utils/routes";
import { makeUrls } from "common/router/utils/urls";
import { PatientKind } from "common/types";

import {
  IncentiveItemsSearch,
  ProductDetailSearch,
  ProductSearchSearch,
  RebateItemsSearch,
} from "../types";

const paths = {
  search: "/",
  category: "/categories/:id",
  incentive: "/incentives/:id",
  rebate: "/rebates/:id",
  productLine: "/:id",
};

type CategoryParams = {
  id: number;
};

type CategorySearch = {
  manufacturer_ids: number[];
  species: PatientKind[];
  page: number;
  prescription_required: boolean[];
};

type IncentiveParams = {
  id: number;
};

type RebateParams = {
  id: number;
};

type DetailParams = {
  id: number;
};

type ProductPathDefinitions = {
  search: { Search: ProductSearchSearch };
  category: { Params: CategoryParams; Search: CategorySearch };
  incentive: { Params: IncentiveParams; Search: IncentiveItemsSearch };
  rebate: { Params: RebateParams; Search: RebateItemsSearch };
  detail: { Params: DetailParams; Search: ProductDetailSearch };
};

export type ProductPaths = MakePaths<ProductPathDefinitions>;

// Import immediately to kick off preloading since clicking on search in the header will open this
// page with the input focused
const productSearch = import("../components/ProductSearchPage");

export const useProductRoutes = (opts: MakeRoutesOpts) => {
  return makeRoutes(opts, [
    { path: paths.search, element: () => productSearch },
    { path: paths.category, element: () => import("../components/CategoryPage") },
    { path: paths.incentive, element: () => import("../components/IncentivePage") },
    { path: paths.rebate, element: () => import("../components/RebatePage") },
    { path: paths.productLine, element: () => import("../components/ProductLinePage") },
  ]);
};

export const makeProductUrls = (base: string) => {
  return makeUrls<ProductPathDefinitions>(base, {
    search: { path: paths.search },
    category: { path: paths.category, to: ({ id }) => `/categories/${id}` },
    incentive: { path: paths.incentive, to: ({ id }) => `/incentives/${id}` },
    rebate: { path: paths.rebate, to: ({ id }) => `/rebates/${id}` },
    detail: { path: paths.productLine, to: ({ id }) => `/${id}` },
  });
};
