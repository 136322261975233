import { DeliveryFulfillerSlug } from "common/types/carts";
import { VendorSlug } from "common/types/vendors";
import { Optional } from "common/utils/types";

const slugsWithFulfillerInfoDrawer = new Set<DeliveryFulfillerSlug>([
  VendorSlug.Truepill,
  VendorSlug.Wedgewood,
  VendorSlug.ExpressScripts,
]);

export const hasFulfillerInfoDrawer = (
  vendorSlug: Optional<DeliveryFulfillerSlug>,
): vendorSlug is DeliveryFulfillerSlug => {
  return !!(vendorSlug && slugsWithFulfillerInfoDrawer.has(vendorSlug));
};
