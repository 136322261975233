import React from "react";

import { Stackable } from "common/stackable/types";

export type StackableOpts = {
  type: Stackable["type"];
  render: Stackable["render"];
  onRemove?: Stackable["onRemove"];
};

type StackableContextValue = {
  push: (opts: StackableOpts) => void;
  removeAll: (type?: string) => void;
};

const StackableContext = React.createContext<StackableContextValue>({
  push: () => console.warn("Oh no! StackableContext.push() is not bound"),
  removeAll: () => console.warn("Oh no! StackableContext.removeAll() is not bound"),
});

export default StackableContext;
