/**
 * Generic set util to combine multiple sets
 * @param sets Each param is a set that adds values to the first set
 * @returns A new set object
 * @example
 * // returns Set([1, 2, 3])
 * union(new Set([1]), new Set([1, 2]), new Set([3]))
 */
export const union = <T>(...sets: Iterable<T>[]) => {
  return new Set(sets.flatMap((set) => [...set]));
};

/**
 * Generic set util to take the difference from a set
 * @param sets Each param is a set that subtracts values from the first set
 * @returns A new set object
 * @example
 * // returns Set([1])
 * difference(new Set([1, 2, 3]), new Set([2]), new Set([3]))
 */
export const difference = <T>(...sets: Iterable<T>[]) => {
  const [first, ...remaining] = sets;
  const toRemove = union(...remaining);
  return new Set([...(first || [])].filter((i) => !toRemove.has(i)));
};

/**
 * Generic set util to get the intersection of sets
 * @param sets Each param is a set whos values will be returned if present in all sets
 * @returns A new set object
 * @example
 * // returns Set([2])
 * intersection(new Set([1, 2, 3]), new Set([2]), new Set([2,4]))
 */
export const intersection = <T>(...sets: Iterable<T>[]) => {
  const setsAsSets = sets.map((set) => new Set(set));
  return new Set([...union(...setsAsSets)].filter((v) => setsAsSets.every((set) => set.has(v))));
};

/**
 * Generic set util to check if all given sets are equal
 * @param sets Each param is a set that will be compared
 * @returns A boolean
 * */
export const equals = <T>(...sets: Iterable<T>[]) => {
  if (!sets.length) {
    return true;
  }
  const setsAsSets = sets.map((set) => new Set(set));
  const targetSize = setsAsSets[0].size;
  return (
    [...setsAsSets].every((set) => set.size === targetSize) &&
    intersection(...setsAsSets).size === targetSize
  );
};

/**
 * Toggles multiple values on and off of a set
 * @param set The set to lookup the value in
 * @param values The values to toggle and off
 * @param addToSet If the values should be added or removed from the set
 * @returns A new set object
 */
export const toggle = <T>(set: Iterable<T>, values: Iterable<T>, addToSet: boolean) => {
  return addToSet ? union(set, values) : difference(set, values);
};

/**
 * Toggles a value on and off of a set
 * @param set The set to lookup the value in
 * @param value The value to toggle and off
 * @returns A new set object
 */
export const toggleValue = <T>(set: Iterable<T>, value: T) => {
  const toggleSet = new Set([...set]);
  return toggle(set, [value], !toggleSet.has(value));
};

/**
 * Generic set util to get whether sets are a subset
 * @param sets All sets to perform the check on
 * @returns if All sets are subsets (not strict) of all following sets
 */
export const isSubset = <T>(...sets: Iterable<T>[]): boolean => {
  return sets.every((set, i) => {
    if (i >= sets.length - 1) {
      // the last set has no impact of if other sets are subsets or not
      return true;
    }
    const nextSet = new Set([...sets[i + 1]]);
    return [...set].every((x) => nextSet.has(x)) && isSubset(sets.slice(i + 1));
  });
};
