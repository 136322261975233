import { useCallback, useMemo } from "react";
import {
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";

import { FakeFetchOpts, FetchRequestOpts, RequestBody } from "common/fetch/types";
import { useTurboFetch, useFakeFetch, useFetch } from "common/fetch/hooks";

import { FetchQueryOptions, SetFetchError, UseQueryResultV5 } from "./types";

/**
 * Temporary wrapper for useQuery until we upgrade to > v5.
 * React query flip-flops the meaning of `isLoading` between versions and `isInitialLoading` isn't
 * the most user-friendly name. Updating the naming to match v5 to avoid more code changes now and
 * to make the future code migration easier. Any consumers of react-query should use this hook
 * and not `useQuery` directly.
 *
 * React query v5 will also not support multiple function signatures and instead expect a single option object.
 */
export const useQueryV5 = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  options: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
): UseQueryResultV5<TData, TError> => {
  const query = useQuery<TQueryFnData, TError, TData, TQueryKey>(options);
  return {
    ...query,
    isLoading: query.isInitialLoading,
    isPending: query.isLoading,
    status: query.status === "loading" ? "pending" : query.status,
  };
};

type UseStateQueryOptions<TQueryFnData, TError, TData, TQueryKey extends QueryKey> = Omit<
  UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
  "staleTime" | "queryFn" | "cacheTime"
> & { default: TQueryFnData };

/**
 * Useful for places where we don't want to make a query but instead use react query
 * as a simple state store.
 *
 * React query v4 seems to produce an error if we try to use it just for state without
 * a query function in some cases.
 *
 * Also sets staleTime to infinity and allows the user to provide a `default` value
 * as the `queryFn` because this seems like it should be sane behavior for most use-cases.
 *
 * We should evaluate the necessity of this hook after upgrading to v5+.
 */
export const useStateQuery = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  options: UseStateQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
): UseQueryResultV5<TData, TError> => {
  return useQueryV5({
    ...options,
    // don't ever consider this data stale (and open for refetching)
    staleTime: Infinity,
    // default query should return initial data if the query has never run or has been invalidated
    queryFn: () => options.default,
  });
};

/**
 * Shortcut for the common pattern of sending a request to the backend on query fetch.
 *
 * This should be treated as syntactic sugar for calling `useFetch` and duplicating type information.
 * The implementation should stay as simple as possible.
 *
 * @typeParam TResponseBody The fetch response.
 * @typeParam TRequestBody The fetch `body`. Used to derive the default
 *   value for `TError` assuming a standard `FetchError`.
 * @typeParam TError The error that can be raised and passed to `onError`. Defaults
 *   to assuming you are using a standard `FetchError`, but this is left open for overriding if needed.
 *
 * @see {@link useFakeFetchQuery} for a mock version of this hook
 * */
export const useFetchQuery = <
  TResponseBody,
  TRequestBody extends RequestBody = void,
  TError = SetFetchError<TRequestBody>,
  TData = TResponseBody,
>(
  key: QueryKey,
  {
    fetch: fetchConfig,
    config: queryConfig,
  }: {
    fetch: FetchRequestOpts<TRequestBody>;
    config?: Omit<UseQueryOptions<TResponseBody, TError, TData>, "queryKey" | "queryFn">;
  },
) => {
  const fetch = useFetch<TResponseBody, TRequestBody>();
  return useQueryV5<TResponseBody, TError, TData>({
    queryKey: key,
    queryFn: () => fetch(fetchConfig),
    ...queryConfig,
  });
};

/**
 * Similar to {@link useFetchQuery} but for prefetching data. Same params but called from
 * a function rather than passed in as hook arguments.
 */
export const useFetchPrefetch = <TResponseBody, TRequestBody extends RequestBody = void>() => {
  const queryClient = useQueryClient();
  const fetch = useFetch<TResponseBody, TRequestBody>();

  return useCallback(
    (
      key: QueryKey,
      {
        fetch: fetchConfig,
      }: {
        fetch: FetchRequestOpts<TRequestBody>;
      },
    ) => {
      return queryClient.ensureQueryData(key, () => fetch(fetchConfig));
    },
    [fetch, queryClient],
  );
};

/**
 * Prefetch function to match useInfiniteQuery. Uses the prefetchInfiniteQuery function
 * from tanstack to prefetch data for an infinite query.
 */
export const useInfiniteFetchPrefetch = <
  TResponseBody,
  TRequestBody extends RequestBody = void,
>() => {
  const queryClient = useQueryClient();
  const fetch = useFetch<TResponseBody, TRequestBody>();

  return useCallback(
    (
      key: QueryKey,
      {
        fetch: fetchConfig,
      }: {
        fetch: FetchRequestOpts<TRequestBody>;
      },
    ) => {
      return queryClient.prefetchInfiniteQuery(key, () => fetch(fetchConfig));
    },
    [fetch, queryClient],
  );
};

/**
 * Same as {@link useFetchQuery} but for hitting the Turbo app.
 * */
export const useTurboQuery = <
  TResponseBody,
  TRequestBody extends RequestBody = void,
  TError = SetFetchError<TRequestBody>,
  TData = TResponseBody,
>(
  key: QueryKey,
  {
    fetch: fetchConfig,
    config: queryConfig,
  }: {
    fetch: FetchRequestOpts<TRequestBody>;
    config?: FetchQueryOptions<TResponseBody, TError, TData>;
  },
) => {
  const fetch = useTurboFetch<TResponseBody, TRequestBody>();
  return useQueryV5<TResponseBody, TError, TData>({
    queryKey: key,
    queryFn: () => fetch(fetchConfig),
    ...queryConfig,
  });
};

/**
 * Same as {@link useFetchQuery} but logs instead of making the request.
 * Useful for mocking out queries when the backend is not quite ready.
 * */
export const useFakeFetchQuery = <
  TResponseBody,
  TRequestBody extends RequestBody = void,
  TError = SetFetchError<TRequestBody>,
>(
  key: QueryKey,
  {
    fetch: fetchConfig,
    genResponse,
    fakeFetchConfig = {},
    config: queryConfig,
  }: {
    fetch: FetchRequestOpts<TRequestBody>;
    genResponse: (body: TRequestBody | undefined) => TResponseBody;
    fakeFetchConfig?: FakeFetchOpts;
    config?: UseQueryOptions<TResponseBody, TError>;
  },
) => {
  const fakeFetch = useFakeFetch<TResponseBody, TRequestBody>();
  const query = useQueryV5<TResponseBody, TError>({
    queryKey: key,
    queryFn: () => fakeFetch(fetchConfig, genResponse, fakeFetchConfig),
    ...queryConfig,
  });
  return query;
};

/**
 * Shortcut for the common pattern of sending a request to the backend on mutate.
 *
 * This should be treated as syntactic sugar for calling `useMutation` and not having to duplicate
 * type information The implementation should stay as simple as possible.
 *
 * @typeParam TVariables The variables passed in as a single argument to the mutation body.
 *   This is the value we use to define a public API for the `mutate` function.
 * @typeParam TResponseBody The response returned by `fetch`, and therefore returned by `onSuccess`.
 *   This is also used to as the default type for the snapshot
 * @typeParam TContext The context returned by `onMutate` and consumed by `onError`.
 *   This is to save any query state before an opportunistic update is applied (and restored if the update fails).
 *   Defaults to TResponseBody | undefined, but some other type may make sense for your use-case.
 * @typeParam TRequestBody The fetch `body`. Used to derive the default
 *   value for `TError` assuming a standard `FetchError`. Defaults to `TVariables` assuming variables
 *   are passed directly through to `fetch`.
 * @typeParam TError The error that can be raised and passed to `onError`. Defaults
 *   to assuming you are using a standard `FetchError`, but this is left open for overriding if needed.
 *
 * @see {@link useFakeFetchMutation} for a mock version of this hook
 * */
export const useFetchMutation = <
  TVariables,
  TResponseBody,
  TContext = TResponseBody | undefined,
  TRequestBody extends RequestBody = TVariables extends {} ? TVariables : void,
  TError = SetFetchError<TRequestBody>,
>({
  fetch: toFetchConfig,
  config: mutationConfig = {},
}: {
  fetch: (variables: TVariables) => FetchRequestOpts<TRequestBody>;
  config?: UseMutationOptions<TResponseBody, TError, TVariables, TContext>;
}) => {
  const fetch = useFetch<TResponseBody, TRequestBody>();
  return useMutation<TResponseBody, TError, TVariables, TContext>(
    (variables) => fetch(toFetchConfig(variables)),
    mutationConfig,
  );
};

/**
 * Same as {@link useFetchMutation} but for hitting the Turbo app.
 * */
export const useTurboMutation = <
  TVariables,
  TResponseBody,
  TContext = TResponseBody,
  TRequestBody extends RequestBody = TVariables extends {} ? TVariables : void,
  TError = SetFetchError<TRequestBody>,
>({
  fetch: toFetchConfig,
  config: mutationConfig = {},
}: {
  fetch: (variables: TVariables) => FetchRequestOpts<TRequestBody>;
  config?: UseMutationOptions<TResponseBody, TError, TVariables, TContext>;
}) => {
  const fetch = useTurboFetch<TResponseBody, TRequestBody>();
  return useMutation<TResponseBody, TError, TVariables, TContext>(
    (variables) => fetch(toFetchConfig(variables)),
    mutationConfig,
  );
};

/**
 * Same as {@link useFetchMutation} but logs instead of sending the request.
 * Useful for mocking out mutations when the backend is not quite ready.
 * */
export const useFakeFetchMutation = <
  TVariables,
  TResponseBody,
  TContext = TResponseBody,
  TRequestBody extends RequestBody = TVariables extends {} ? TVariables : void,
  TError = SetFetchError<TRequestBody>,
>({
  fetch: toFetchConfig,
  genResponse,
  fakeFetchConfig = {},
  config: mutationConfig = {},
}: {
  fetch: (variables: TVariables) => FetchRequestOpts<TRequestBody>;
  genResponse: (body: TVariables) => TResponseBody;
  fakeFetchConfig?: FakeFetchOpts;
  config?: UseMutationOptions<TResponseBody, TError, TVariables, TContext>;
}) => {
  const fakeFetch = useFakeFetch<TResponseBody, TRequestBody>();
  return useMutation<TResponseBody, TError, TVariables, TContext>(
    (variables) =>
      fakeFetch(toFetchConfig(variables), () => genResponse(variables), fakeFetchConfig),
    mutationConfig,
  );
};

type RollbackOpts<TQueryData, TVariables> = {
  queryData: TQueryData;
  originalData: TQueryData;
  variables: TVariables;
};

type OptimisticMutationConfig<TVariables, TQueryData, TResponseBody> = {
  /** The query keys of the data to update optimistically. Returns TContext */
  dataKeys: QueryKey[];
  /** The extra query keys that will be canceled before running the optimistic update and invalidated after */
  invalidateKeys?: QueryKey[];
  /** The function that will be called to update the data. */
  update: (queryData: TQueryData, variables: TVariables) => TQueryData;
  /**
   * The key to use to identify this mutation so we only ever run a single invalidation once
   * all simultaneous mutations are complete. In general we don't need to do this, but there are some cases
   * such as list views that encourage the user to make many simultaneous slower updates, where it is more likely
   * to cause jumping in the UI.
   */
  deferInvalidationMutationKey?: QueryKey;
  /**
   * A custom rollback function to run on error. By default we assume we want to rollback all data to
   * to it's original value, though this may not always be the case, such as in bulk updates from list views.
   */
  rollback?: (opts: RollbackOpts<TQueryData, TVariables>) => TQueryData;
  /**
   * If passed in, this will be called to update the data after the mutation is successful.
   */
  updateFromResponse?: (response: TQueryData, variables: TResponseBody) => TQueryData;
};

type OptimisticMutationContext<TQueryData> = [QueryKey, TQueryData][] | undefined;

/**
 * Shortcut for optimstic updates for backend mutations.
 *
 * This should be treated as syntactic sugar for calling `useFetchMutation` and not having to
 * implement all of the boilerplate for optimistic updates.
 *
 * @see {@link useFetchMutation} for the underlying type params
 * @see https://tanstack.com/query/v3/docs/react/guides/optimistic-updates for offical docs
 * */
export const useOptimisticFetchMutation = <
  TVariables,
  TResponseBody,
  TQueryData = TResponseBody,
  TRequestBody extends RequestBody = TVariables extends {} ? TVariables : void,
  TError = SetFetchError<TRequestBody>,
>({
  fetch: toFetchConfig,
  config: mutationConfig = {},
  optimistic: optimisticConfig,
}: {
  fetch: (variables: TVariables) => FetchRequestOpts<TRequestBody>;
  config?: UseMutationOptions<
    TResponseBody,
    TError,
    TVariables,
    OptimisticMutationContext<TQueryData> | void
  >;
  optimistic: OptimisticMutationConfig<TVariables, TQueryData, TResponseBody>;
}) => {
  const queryClient = useQueryClient();
  const { dataKeys, updateFromResponse } = optimisticConfig;

  /**
   * Checks if we should defer invalidation or not. Generally we should do this if the
   * there are multiple (other) mutations still running, so that only the last mutation
   * matching the deferInvalidationMutationKey will invalidate the queries.
   */
  const shouldDeferInvalidation = () =>
    optimisticConfig.deferInvalidationMutationKey
      ? queryClient.isMutating({ mutationKey: optimisticConfig.deferInvalidationMutationKey }) > 1
      : false;

  const invalidateKeys = optimisticConfig.invalidateKeys ?? [];

  /**
   * Run before the mutation is executed. Cancel these queries so that they don't run while we
   * are updating the data and get things into a weird state (especially since we're
   * invalidating them onSettle)
   */
  const cancelQueries = async () => {
    return Promise.all(
      [...dataKeys, ...invalidateKeys].map((key) => queryClient.cancelQueries(key)),
    );
  };

  /**
   * Run after the mutation is executed. Invalidate these queries so that they will be refetched
   */
  const invalidateQueries = async () => {
    const keys = [
      // also invalidate the data key if we are not otherwise updating from the response
      // to handle the common case
      ...(!updateFromResponse ? dataKeys : []),
      ...invalidateKeys,
    ];

    return Promise.all(keys.map((key) => queryClient.invalidateQueries(key)));
  };

  /**
   * Perform the optimistic update. This is called before the mutation is executed.
   */
  const updateData = (updater: (data: TQueryData) => TQueryData): [QueryKey, TQueryData][] => {
    const queryFilter = (key: QueryKey) => ({
      queryKey: key,
      // don't include inactive queries to avoid extra processing of not actively viewed data
      inactive: false,
    });

    const originalDatas = dataKeys
      .flatMap((key) => queryClient.getQueriesData<TQueryData>(queryFilter(key)))
      .flatMap<[QueryKey, TQueryData]>(([key, data]) => (data ? [[key, data]] : []));

    dataKeys.forEach((key) => {
      queryClient.setQueriesData<TQueryData>(queryFilter(key), (data) => data && updater(data));
    });

    // Return the original data so that we can snapshot it in onMutate
    return originalDatas;
  };

  /**
   * Rollback the optimistic update. This is called after the mutation is executed and ends up in
   * an error state.
   */
  const rollbackData = (
    originalDatas: OptimisticMutationContext<TQueryData>,
    variables: TVariables,
  ) => {
    if (!originalDatas) {
      return;
    }

    originalDatas.forEach(([key, originalData]) =>
      queryClient.setQueryData<TQueryData | undefined>(key, (queryData) => {
        if (!queryData) {
          return queryData;
        }
        return optimisticConfig.rollback
          ? optimisticConfig.rollback({ queryData, originalData, variables })
          : originalData;
      }),
    );
  };

  /**
   * Direct access to performing the optimistic update. This is useful if you need to perform
   * an optimistic update in a different context than the mutation (e.g. onSuccess of a form)
   */
  const updateOptimisticData = useCallback(async (variables: TVariables) => {
    await cancelQueries();
    updateData((data) => optimisticConfig.update(data, variables));
    await invalidateQueries();

    // In practice, the dependent keys will never change, so we can safely disable the lint rule
    // for now. But if we ever need to change this, we should be able to do so by adding a
    // JSON.stringify of the dependent keys to the deps array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mutation = useFetchMutation<
    TVariables,
    TResponseBody,
    OptimisticMutationContext<TQueryData>,
    TRequestBody,
    TError
  >({
    fetch: toFetchConfig,
    config: {
      ...mutationConfig,
      onMutate: async (...params) => {
        await mutationConfig.onMutate?.(...params);
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await cancelQueries();
        return updateData((data) => optimisticConfig.update(data, params[0]));
      },
      onError: async (...params) => {
        const [, variables, originalData] = params;
        rollbackData(originalData, variables);
        return mutationConfig.onError?.(...params);
      },
      onSettled: async (...params) => {
        if (!shouldDeferInvalidation()) {
          await invalidateQueries();
        }

        return mutationConfig.onSettled?.(...params);
      },
      onSuccess: async (...params) => {
        if (updateFromResponse) {
          updateData((data) => updateFromResponse(data, params[0]));
        }

        return mutationConfig.onSuccess?.(...params);
      },
    },
  });

  return useMemo(() => ({ ...mutation, updateOptimisticData }), [mutation, updateOptimisticData]);
};
