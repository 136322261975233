/** Replace the nth element in the array, resulting in a new array.
 * @param arr The input array
 * @param n Index to replace, if negative will start from the end of the array
 * numbers that exceed the first or last index will be ignored and result in
 * an unchanged arrays
 * @param val The value to insert at n
 * @returns The new array with val replaced at n
 */
export const replaceNth = <T, V>(arr: T[], n: number, val: V) => {
  const sliceAt = n < 0 ? arr.length + n : n;
  if (sliceAt < 0 || sliceAt >= arr.length) {
    return arr;
  }
  return [...arr.slice(0, sliceAt), val, ...arr.slice(sliceAt + 1, arr.length)];
};

/**
 *
 * @param minimum The inclusive minimum value of the range
 * @param maximum The exclusive maximum value of the range
 * @param value The value that should cycle
 */
export const cycleIndex = (minimum: number, maximum: number, value: number) => {
  if (value < minimum) {
    return maximum - 1;
  }

  if (value >= maximum) {
    return minimum;
  }

  return value;
};

/**
 * Filters the provided array into an array of valid options.
 * Useful for ensuring that strings are actually valid Enum values,
 * and for converting from a generic string[] to a more specific Enum[]
 *
 * @param str The array to filter
 * @param validSet The set of valid options to allow
 * @returns An array of valid values
 */
export const filterArrayForValidValues = <T extends string = string>(
  arr: string[],
  validSet: Set<T>,
) => arr.flatMap((elem) => (validSet.has(elem as T) ? [elem as T] : []));

/**
 * Chunks an array into smaller arrays of size `size`
 *
 * @param arr The array to chunk
 * @param size The chunk size
 * @returns An array of arrays, each of size `size`
 */
export const chunkArray = <T>(arr: T[], size: number) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (_, i) =>
    arr.slice(i * size, i * size + size),
  );
