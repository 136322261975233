import React, { ReactNode, useState } from "react";

import LoadStatusProvider from "common/load/LoadStatusProvider";
import errorImages from "common/constants/errorImages";

import ErrorPage from "../components/errors/ErrorPage";
import EmptyPage from "../components/layouts/EmptyPage";
import ConfirmationDialog from "../components/content/ConfirmationDialog";
import Dialog from "../components/stackables/Dialog";

type ReloadRequiredDialogProps = {
  isVisible: boolean;
};

const ReloadRequiredDialog = ({ isVisible }: ReloadRequiredDialogProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const performReload = () => {
    setIsLoading(true);
    if (!isLoading) {
      window.location.reload();
    }
  };
  return (
    <Dialog.Standalone isVisible={isVisible} onClose={performReload}>
      <ConfirmationDialog
        title="Your session has expired"
        description="Please refresh the page and try again."
        confirmText="Refresh Page"
        onDeny={performReload}
        onConfirm={performReload}
        hasDenyButton={false}
      />
    </Dialog.Standalone>
  );
};

/**
 * Global error page to show any global frontend errors. Do not include any data dependencies here
 * since that is a common point of failure (ex: no useClinic())
 */
const ConnectionErrorPage = () => {
  return (
    <ErrorPage
      refresh={true}
      PageComponent={EmptyPage}
      image={errorImages.ErrorDefault}
      imageAlt="Connection Problem"
      title="Oh No!"
      description="It looks like we ran into some issues loading this page. Try refreshing your browser and if that doesn't help, verify that you have a stable internet connection."
      hasRefreshButton={true}
    />
  );
};

type ClientLoadStatusProviderProps = {
  children: ReactNode;
};

const ClientLoadStatusProvider = ({ children }: ClientLoadStatusProviderProps) => {
  return (
    <LoadStatusProvider
      fallback={<ConnectionErrorPage />}
      renderReloadRequiredOverlay={(p) => <ReloadRequiredDialog {...p} />}
    >
      {children}
    </LoadStatusProvider>
  );
};

export default ClientLoadStatusProvider;
