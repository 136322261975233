import React from "react";

import errorImages from "common/constants/errorImages";

import ErrorPage from "./ErrorPage";
import EmptyPage from "../layouts/EmptyPage";

/**
 * Global error page to show any global frontend errors. Do not include any data dependencies here
 * since that is a common point of failure (ex: no useClinic())
 */
const GlobalErrorPage = () => {
  return (
    <ErrorPage
      refresh={true}
      PageComponent={EmptyPage}
      image={errorImages.Error500}
      imageAlt="Internal Error"
      title="Oops!"
      description="The server encountered an internal error or misconfiguration and was unable to complete your request. "
    />
  );
};

export default GlobalErrorPage;
