import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useNavigate } from "common/router/hooks";

import { urls } from "client/routes";

import SearchContext from "./SearchContext";

/**
 * Creates an input that's hidden in the DOM
 */
const createHiddenInput = () => {
  const hiddenInput = document.createElement("input");
  hiddenInput.style.position = "absolute";
  hiddenInput.style.top = "0";
  hiddenInput.style.height = "0";
  hiddenInput.style.opacity = "0";
  return hiddenInput;
};

type SearchProviderProps = { children: React.ReactNode };

const SearchProvider = (props: SearchProviderProps) => {
  const { children } = props;

  const navigate = useNavigate();

  const [shouldFocus, setShouldFocus] = useState(false);
  const temporaryInputRef = useRef<HTMLInputElement>();
  const intervalRef = useRef<ReturnType<typeof setInterval>>();
  const searchInputRef = useRef<HTMLInputElement>(null);

  /**
   * Since we navigate to another page for search, iOS sees trying to autofocus the search
   * input as not a user interaction and won't bring up the keyboard. This creates a hidden
   * input we can focus on during the user interaction to bring up the keyboard and transfer
   * that focus once the real search input is visible. Then the temporary input is removed.
   */
  const focusSearchInput = useCallback(() => {
    setShouldFocus(true);
    navigate(urls.products.search());
    temporaryInputRef.current = createHiddenInput();
    document.body.appendChild(temporaryInputRef.current);

    // Focus the input in this callback to bring up the keyboard. Trying to do this in a useEffect
    // or anything without a user action will prevent iOS from displaying the keyboard
    temporaryInputRef.current.focus();

    // Enter an interval to detect when the true input is ready. Done here without a useEffect
    // or callback so that focus can be transfered within this user action callback
    intervalRef.current = setInterval(() => {
      if (searchInputRef.current) {
        searchInputRef.current.focus();
        temporaryInputRef.current?.remove();

        // Clear the interval so we stop trying to focus on the searchInput
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
      }
    }, 100);
  }, [navigate]);

  useEffect(() => {
    // Cleanup the interval and the temporaryInput to help avoid any leaks
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        temporaryInputRef.current?.remove();
      }
    };
  }, []);

  const value = useMemo(
    () => ({
      searchInputRef,
      focusSearchInput,
      shouldFocus,
      setShouldFocus,
    }),
    [focusSearchInput, shouldFocus],
  );

  return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>;
};

export default SearchProvider;
