import { useContext, useEffect, useState } from "react";
import deepEqual from "fast-deep-equal/es6/react";

import { BREAKPOINTS } from "common/constants/tokens";

import SearchContext from "../contexts/SearchContext";

type Breakpoints = {
  isDesktopAndSmaller: boolean;
  isTabletLandscapeAndSmaller: boolean;
  isTabletPortraitAndSmaller: boolean;
  isPhoneAndSmaller: boolean;
};

export const useSearchContext = () => {
  return useContext(SearchContext);
};

const getBreakpointsFromWidth = (width: number): Breakpoints => ({
  isDesktopAndSmaller: width <= BREAKPOINTS.desktopAndSmaller,
  isTabletLandscapeAndSmaller: width <= BREAKPOINTS.tabletLandscapeAndSmaller,
  isTabletPortraitAndSmaller: width <= BREAKPOINTS.tabletPortraitAndSmaller,
  isPhoneAndSmaller: width <= BREAKPOINTS.phoneAndSmaller,
});

export const useBreakpoints = () => {
  const [breakpoints, setBreakpoints] = useState<Breakpoints>(() =>
    getBreakpointsFromWidth(window.innerWidth),
  );

  useEffect(() => {
    const resize = () => {
      setBreakpoints((current) => {
        // Only update state when any value actually changes to cut down on renders
        const next = getBreakpointsFromWidth(window.innerWidth);
        return deepEqual(current, next) ? current : next;
      });
    };

    window.addEventListener("resize", resize);

    return () => window.removeEventListener("resize", resize);
  }, []);

  return breakpoints;
};
