import tokens from "common/styles/tokens/exports.less";

/**
 * Breakpoints in pixels
 */
export const BREAKPOINTS = {
  desktopAndSmaller: parseInt(tokens["breakpoint-desktop-and-smaller"], 10),
  tabletLandscapeAndSmaller: parseInt(tokens["breakpoint-tablet-landscape-and-smaller"], 10),
  tabletPortraitAndSmaller: parseInt(tokens["breakpoint-tablet-portrait-and-smaller"], 10),
  phoneAndSmaller: parseInt(tokens["breakpoint-phone-and-smaller"], 10),
};

export type TransitionDuration = "faster" | "fast" | "base";

/**
 * Transition durations in milliseconds
 */
export const TRANSITION_DURATIONS: Record<TransitionDuration, number> = {
  faster: parseInt(tokens["transition-duration-faster"], 10),
  fast: parseInt(tokens["transition-duration-fast"], 10),
  base: parseInt(tokens["transition-duration-base"], 10),
};
