import { makeRoutes, MakeRoutesOpts } from "common/router/utils/routes";
import { MakePaths } from "common/router/types";
import { makeUrls } from "common/router/utils/urls";
import { ClientHomeSearch } from "common/types/clinics";

const paths = {
  root: "/",
};

type HomePathDefinitions = {
  root: { Search: ClientHomeSearch };
};

export type HomePaths = MakePaths<HomePathDefinitions>;

export const useHomeRoutes = (opts: MakeRoutesOpts) => {
  return makeRoutes(opts, [
    {
      path: paths.root,
      element: () => import("../components/HomePage"),
    },
  ]);
};

export const makeHomeUrls = (base: string) => {
  return makeUrls<HomePathDefinitions>(base, {
    root: { path: paths.root },
  });
};
