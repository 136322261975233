import React, { ReactNode } from "react";
import { IntercomProvider } from "react-use-intercom";

import { getInitialClientData } from "../utils";

import variables from "client/common/styles/export-variables.less";

type ClientIntercomProviderProps = {
  children: ReactNode;
};

const ClientIntercomProvider = ({ children }: ClientIntercomProviderProps) => {
  const initialData = getInitialClientData();

  return (
    <IntercomProvider
      appId={initialData.intercom_id}
      autoBoot={!!initialData.intercom_id}
      autoBootProps={{
        userId: initialData.user?.id.toString(),
        userHash: initialData.intercom_user_hash || undefined,
        backgroundColor: variables.intercomColor,
        actionColor: variables.intercomColor,
        hideDefaultLauncher: true,
        customAttributes: {
          "Current Clinic ID": initialData.clinic.id,
          "Current Clinic Name": initialData.clinic.internal_name,
        },
      }}
    >
      {children}
    </IntercomProvider>
  );
};

export default ClientIntercomProvider;
