import { IPatientWrite, IPatientWriteBody, PatientKind } from "common/types";

import { PasswordCondition } from "../types";
import cat from "../images/species/cat.svg";
import catBlue from "../images/species/cat-blue.svg";
import dog from "../images/species/dog.svg";
import dogBlue from "../images/species/dog-blue.svg";
import paw from "../images/species/paw.svg";
import pawBlue from "../images/species/paw-blue.svg";
import horse from "../images/species/horse.svg";
import horseBlue from "../images/species/horse-blue.svg";

export const passwordConditions: PasswordCondition[] = [
  { label: "Password must be 8 characters long", check: (p) => p.length >= 8 },
];

export const patientWriteToPatientUpdateBody = (
  values: Partial<IPatientWrite>,
): Partial<IPatientWriteBody> => {
  const { primary_breed: primaryBreed, secondary_breeds: secondaryBreeds, color, ...rest } = values;

  return {
    ...rest,
    ...(primaryBreed && {
      primary_breed: primaryBreed.value,
    }),
    ...(secondaryBreeds && {
      secondary_breeds: secondaryBreeds.map((b) => b.value),
    }),
    ...(color && {
      color: color.value,
    }),
  };
};

export const imageForSpecies = (species: PatientKind, isActive: boolean) => {
  switch (species) {
    case PatientKind.Feline:
      return isActive ? catBlue : cat;
    case PatientKind.Canine:
      return isActive ? dogBlue : dog;
    case PatientKind.Equine:
      return isActive ? horseBlue : horse;
    default:
      return isActive ? pawBlue : paw;
  }
};
