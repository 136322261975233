import cx from "classnames";
import React from "react";

import styles from "../styles/components/Angle.module.less";

type AngleProps = {
  direction: "up" | "right" | "down" | "left";
  className?: string;
  isActive?: boolean;
};

const Angle = ({ className, direction, isActive }: AngleProps) => {
  return (
    <div className={cx(styles.container, styles[direction], isActive && styles.active, className)}>
      <div className={styles.rotator}>
        <div className={styles.angle} />
      </div>
    </div>
  );
};

export default Angle;
