import React from "react";
import cx from "classnames";
import {
  faAngleDown,
  faArrowLeft,
  faArrowRight,
  IconDefinition,
} from "@fortawesome/pro-regular-svg-icons";

import LinkButton, { LinkButtonProps } from "common/components/LinkButton";
import Icon from "common/components/Icon";

import styles from "../../styles/TextButton.module.less";

export type TextButtonProps = LinkButtonProps & {
  hasUnderline?: boolean;
  icon?: "left" | "right" | "down" | IconDefinition;
  variant?: "default" | "semi-transparent" | "dark";
};

const TextButton = ({
  hasUnderline,
  icon,
  className,
  children,
  variant = "default",
  ...other
}: TextButtonProps) => {
  const getPreIcon = () => {
    if (icon === "left") {
      return <Icon className={cx(styles.preIcon, styles.angleLeft)} icon={faArrowLeft} />;
    }

    return null;
  };

  const getPostIcon = () => {
    if (icon === "right") {
      return <Icon className={cx(styles.postIcon, styles.angleRight)} icon={faArrowRight} />;
    }

    if (icon === "down") {
      return <Icon className={cx(styles.postIcon, styles.angleDown)} icon={faAngleDown} />;
    }

    // Wildcard case
    if (icon && typeof icon !== "string") {
      return <Icon className={cx(styles.postIcon, styles.any)} icon={icon} />;
    }

    return null;
  };

  return (
    <LinkButton
      className={cx(
        styles.container,
        hasUnderline && styles.underline,
        styles[`variant-${variant}`],
        className,
      )}
      {...other}
    >
      {getPreIcon()}
      {children}
      {getPostIcon()}
    </LinkButton>
  );
};

export default TextButton;
