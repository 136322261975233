import { DynamicStackable, Stackable } from "common/stackable/types";

/**
 * Partitions stacks by type, where each new sequence of stackables of the same type will
 * be in the same array.
 */
export const partitionStackByType = <T extends Stackable>(stack: T[]): T[][] => {
  const partitioned: T[][] = [];
  let currentStack: T[] = [];
  stack.forEach((stackable) => {
    if (!currentStack.length || stackable.type === currentStack[currentStack.length - 1].type) {
      currentStack.push(stackable);
    } else {
      partitioned.push(currentStack);
      currentStack = [stackable];
    }
  });
  if (currentStack.length) {
    partitioned.push(currentStack);
  }
  return partitioned;
};

/**
 * @stack The array of stackables to annotate
 * @returns A new array of stackables with an additional `visibleIndex`, indicating the
 *         index of the stackable in the visible stack, or null if the stackable is not visible.
 */
export const annotateVisibleIndexes = <T extends DynamicStackable>(
  stack: T[],
): (T & { visibleIndex: number | null })[] => {
  let i = 0;
  return stack.map((stackable) => {
    const visible = !stackable.removedAt;
    const annotated = { ...stackable, visibleIndex: visible ? i : null };
    if (visible) {
      i += 1;
    }
    return annotated;
  });
};
