import { useFetchMutation } from "common/queries/hooks";

import { urls } from "./constants";
import { CampaignJourneyCreate } from "../types";

export const useCreateCampaignJourney = () => {
  return useFetchMutation<CampaignJourneyCreate, null>({
    fetch: (body) => ({ method: "POST", url: urls.applyCampaignJourney(), body }),
  });
};
