export const urls = {
  clinic: () => "/api/client/v1/clinics/clinic/",
  storefrontThemeColors: () => "/api/client/v1/clinics/storefront-theme-colors/",
};

export const keys = {
  all: () => ["clinics-all"],
  // clinics
  clinics: () => [...keys.all(), "clinics"],
  clinicDetails: () => [...keys.clinics(), "detail"],
  activeClinicDetail: () => [...keys.clinicDetails(), "active"],
  // storefront theme colors
  storefrontThemeColors: () => [...keys.all(), "storefront-theme-colors"],
  storefrontThemeColorList: () => [...keys.storefrontThemeColors(), "list"],
};
