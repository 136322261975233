import { IPaginator } from "common/types";
import { getInitialData } from "common/utils/initialization";
import { rangeArray } from "common/utils/numbers";
import { BasicAuthUser } from "common/auth/types";
import { IntegrationSettings } from "common/types/accounts";

import { Clinic } from "client/clinics/types";
import { AuthenticationSource } from "client/accounts/types";

/** matches general ClientBundle initial_data */
type InitialData = {
  clinic: Clinic;
  is_authenticated: boolean;
  auth_source: AuthenticationSource | null;
  has_completed_signup: boolean;
  is_staff: boolean;
  vetcove_support_phone: number;
  vetcove_support_email: string;
  vetcove_support_hours: string;
  user: BasicAuthUser | null;
  intercom_id: string;
  intercom_user_hash: string | null;
  integration_settings: IntegrationSettings;
  has_internal_analytics_tracking: boolean;
};

export const getInitialClientData = () => {
  return getInitialData<InitialData>();
};

export const getPageOptions = (paginator: IPaginator) => {
  const totalPages = Math.ceil(Math.max(paginator.count, 1) / Math.max(paginator.page_size, 1));
  const start = paginator.page - 3;
  const end = paginator.page + 3;
  const overflowMin = Math.max(1 - start, 0);
  const overflowMax = Math.max(end - totalPages, 0);

  const minPage = Math.max(start - overflowMax, 1);
  const maxPage = Math.min(end + overflowMin, totalPages);

  const pages = rangeArray(minPage, maxPage + 1);

  const showFirstPage = minPage > 1;
  const showFirstDots = minPage > 2;
  const showLastPage = maxPage < totalPages;
  const showLastDots = maxPage < totalPages - 1;
  const canPrevious = paginator.page > 1;
  const canNext = Math.max(paginator.page, 1) < totalPages;

  return {
    pages,
    showFirstPage,
    showFirstDots,
    showLastPage,
    showLastDots,
    canPrevious,
    canNext,
    nextPage: paginator.page + 1,
    previousPage: paginator.page - 1,
    firstPage: 1,
    lastPage: totalPages,
    totalPages,
  };
};
