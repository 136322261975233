import { stringify } from "query-string";
import * as Sentry from "@sentry/react";
import { Severity } from "@sentry/react";

import { delay } from "common/utils/async";
import { Optional } from "common/utils/types";

import {
  FieldErrors,
  FakeFetchOpts,
  FetchMethod,
  RequestBody,
  StatusCode,
  FatalFetchErrorKind,
  ResponseContentType,
} from "./types";
import { FatalFetchError, FetchError } from "./errors";

/**
 * The set of expected client error codes.  Because client error codes generally won't be logged
 * on the server (they are the responsibility of the client), we should handle them explicitly in
 * the app.
 *
 * We generally assume that the UI for writes on "forbidden" resources will be disabled/hidden
 * and that any not found resources is a broken assumption of the FE expecting some BE main resource/path
 * that doesn't exist.
 *
 * @see StatusCode for an explanation of what we do if we see each type of error
 *
 */
const EXPECTED_WRITE_CLIENT_ERROR_CODES = [StatusCode.BadRequest, StatusCode.Unauthorized];

/**
 * Similar to `EXPECTED_WRITE_CLIENT_ERROR_CODES` but only applies to GET requests
 * we generally assume that the BE will gracefully handle our read requests
 */
const EXPECTED_READ_CLIENT_ERROR_CODES = [
  StatusCode.Unauthorized,
  StatusCode.Forbidden,
  StatusCode.NotFound,
];

const isClientErrorCode = (status: number | string) => {
  return status.toString()[0] === "4";
};

/**
 * Practically we won't be able to handle ALL error codes, so we check to make
 * sure the error returned is an error we've opted into handling.
 * Any un-handled codes is most likely a broken assumption between the client and the server
 * and should be logged.
 *  */
const shouldReportError = (response: Response, method: FetchMethod) => {
  if (response.ok || !isClientErrorCode(response.status)) {
    return false;
  }
  if (method === "GET") {
    return !EXPECTED_READ_CLIENT_ERROR_CODES.includes(response.status);
  }
  return !EXPECTED_WRITE_CLIENT_ERROR_CODES.includes(response.status);
};

/** Get the data returned from the server, but don't try to parse empty or unexpected content */
const extractPayload = async (
  response: Response,
  responseContentType: ResponseContentType,
): Promise<unknown> => {
  if (response.status === StatusCode.NoContent) {
    return undefined;
  }
  // Use `includes` in case the server returns a charset with the content type
  // (eg. `application/json; charset=utf-8`)
  if (responseContentType.includes("application/json")) {
    return response.json();
  }
  if (responseContentType.includes("application/pdf")) {
    return response.blob();
  }
  return undefined;
};

const extractErrorDetailString = (body: unknown) => {
  if (typeof body !== "object" || !body || !("detail" in body)) {
    return null;
  }
  const safeBody: { detail?: unknown } = body;
  return typeof safeBody.detail === "string" ? safeBody.detail : null;
};

/**
 * Probes response and body for fatal fetch errors, returns `null` if response
 * is not a fatal fetch error.
 */
const getFatalError = (response: Response, body: unknown): Optional<FatalFetchError> => {
  if (response.ok) {
    return null;
  }
  const detail = extractErrorDetailString(body) ?? "Something went wrong.";
  if (response.status === StatusCode.Unauthorized) {
    return new FatalFetchError(FatalFetchErrorKind.Unauthorized, response.status, detail);
  }
  if (response.status === StatusCode.Forbidden && /^CSRF Failed/.test(detail)) {
    return new FatalFetchError(FatalFetchErrorKind.InvalidCSRFToken, response.status, detail);
  }
  return null;
};

export interface PerformFetchOpts<T extends RequestBody> {
  /** Token to pass along to the BE to validate requests */
  csrfToken: string;
  /** The url you want to send the request to  */
  url: string;
  /** The HTTP method to use to send the request. Defaults to `GET`. */
  method?: FetchMethod;
  /** The body (or url params in the case of `GET`) for the request */
  body?: T;
  /** The content type we expect to get back */
  responseContentType?: ResponseContentType;
}

/**
 * Low-level util to perform HTTP requests. Generally you should use
 * the app provided {@link fetch/hooks!useFetch}.
 *
 * See {@link performFakeFetch} for a mock version of this function for testing.
 */
// TODO: Move to barnyard and abstract the common pieces between all fetches
const performFetch = async <T>({
  csrfToken,
  url,
  method = "GET",
  body = {},
  responseContentType = "application/json",
}: PerformFetchOpts<RequestBody>): Promise<T> => {
  let urlToFetch = url;

  // Set up the proper headers
  const options: RequestInit = {
    method,
    headers: [],
  };

  // Add the CSRF token and set request as json type if not a GET request
  if (method !== "GET") {
    options.headers = [
      ["X-CSRFToken", csrfToken],
      ["Content-Type", "application/json"],
    ];
  }

  // JSON stringify the body or parameterize it
  if (Object.keys(body).length) {
    if (method === "GET") {
      urlToFetch += `?${stringify(body, { arrayFormat: "comma" })}`;
    } else {
      options.body = JSON.stringify(body);
    }
  }

  // Fetch may throw for a variety of reasons, handle these gracefully
  // See: https://stackoverflow.com/questions/49343024/getting-typeerror-failed-to-fetch-when-the-request-hasnt-actually-failed
  let response;
  try {
    response = await fetch(urlToFetch, options);
  } catch (error) {
    Sentry.addBreadcrumb({
      category: "response",
      type: "info",
      message: `${method} to ${url} caused fetch to throw an error`,
      data: { options, error },
    });
    Sentry.captureMessage(`Error was raised from fetch for ${method} to ${url}`, Severity.Warning);

    throw new FetchError(
      500,
      "Trouble connecting to Vetcove. If this issue continues, please reach out to Vetcove via the icon in the bottom right corner.",
      {},
    );
  }

  // Extract the response body gracefully
  let responseBody;
  try {
    // Clone the response for extraction because we can only read the body once
    // and we may need to read it again for debugging purposes
    responseBody = await extractPayload(response.clone(), responseContentType);
  } catch (error) {
    const text = await response.clone().text();
    Sentry.addBreadcrumb({
      category: "response",
      type: "info",
      message: `${method} to ${url} caused extractPayload to throw an error`,
      data: { options, response: text, status: response.status, error },
    });
    Sentry.captureMessage(
      `Error was raised from extractPayload for ${method} to ${url}`,
      Severity.Warning,
    );

    throw new FetchError(
      500,
      "Trouble connecting to Vetcove. If this issue continues, please reach out to Vetcove via the icon in the bottom right corner.",
      {},
    );
  }

  const fatalFetchError = getFatalError(response, responseBody);
  if (fatalFetchError) {
    throw fatalFetchError;
  }

  // log before any other error handling so we don't swallow this message
  if (shouldReportError(response, method)) {
    Sentry.addBreadcrumb({
      category: "response",
      type: "info",
      message: `${method} to ${url} responded with a ${response.status}`,
      data: { payload: body, response: responseBody },
    });
    Sentry.captureMessage(`Status code ${response.status} unexpected for ${method} to ${url}`, {
      level: Severity.Error,
    });
  }

  // Protecting against times where we are returned a response content type that does not
  // match the expected response content type
  const contentType = response.headers.get("content-type");
  // Use `includes` in case the server returns a charset with the content type,
  // for example: `application/json; charset=utf-8`
  if (contentType && !contentType.includes(responseContentType)) {
    // Throw so react-query knows that we errored
    throw new FetchError(
      response.status,
      "Trouble connecting to Vetcove. If this issue continues, please reach out to Vetcove via the icon in the bottom right corner.",
      {},
    );
  }

  if (!response.ok) {
    const errorPayload = responseBody as FieldErrors<T> & {
      detail?: string;
      data?: { [key: string]: any };
    };
    const errors = { ...errorPayload };
    const { data } = errors;
    delete errors.detail;
    delete errors.data;
    // Throw so react-query knows that we errored
    throw new FetchError<T>(response.status, errorPayload.detail, errors as FieldErrors<T>, data);
  }

  return responseBody as T;
};

/**
 * Implements the same interface as {@link performFetch} but prints to the console instead
 * of actually sending an API request. Useful for mocking out API requests but
 * keeping the rest of the surrounding code close to what you would expect in
 * the finished iteration
 *
 * @param genResponse produces the response that you want to return from this API call
 * if an exception is thrown when this function is called it will consider the thrown value
 * to be an error response
 */
export const performFakeFetch = async <B extends RequestBody, R>(
  { url, body, method }: PerformFetchOpts<B>,
  genResponse: (body: B | undefined) => R,
  { delayMs = 750 }: FakeFetchOpts = {},
): Promise<R> => {
  // allow for a console log here since this is a dev only util
  // eslint-disable-next-line no-console
  console.log(
    `TODO: mocked API call would send a ${method ?? "GET"} request ${
      body ? `with ${JSON.stringify(body)}` : ""
    } to "${url}"`,
  );
  await delay(delayMs);
  return genResponse(body);
};

export default performFetch;
