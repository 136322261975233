import React, { useContext } from "react";

type CartContextValue = {
  isCartPrebuilt: boolean;
  isCartMutationSuccess: boolean;
};

const CartContext = React.createContext<CartContextValue>({
  isCartPrebuilt: false,
  isCartMutationSuccess: false,
});

export const useCartContext = () => {
  return useContext(CartContext);
};

export default CartContext;
