const INITIAL_DATA_ID = "initial-data";
const INITIAL_DATA_ATTRIBUTE = "data-react";
const SENTRY_DATA_ID = "sentry-user";

export const getSentryUserdata = (): Object | null => {
  const initialStringifiedData = document
    .getElementById(SENTRY_DATA_ID)
    ?.getAttribute(INITIAL_DATA_ATTRIBUTE);

  if (!initialStringifiedData) {
    return null;
  }

  return JSON.parse(initialStringifiedData);
};

/**
 * Gets the initial data set on the backend.
 * Set on `<meta id='initial-data' data-react='{...}' />`
 * @typeParam T the expected type of the data, as a contract with the server
 * */
export const getInitialData = <T>(): T => {
  const initialStringifiedData = document
    .getElementById(INITIAL_DATA_ID)
    ?.getAttribute(INITIAL_DATA_ATTRIBUTE);

  if (!initialStringifiedData) {
    throw new Error(
      "No initial data found!" +
        `Please make sure you have an element with an id of '${INITIAL_DATA_ID}' ` +
        `and an attribute of '${INITIAL_DATA_ATTRIBUTE}'. ` +
        `Example: <meta id='${INITIAL_DATA_ID}' ${INITIAL_DATA_ATTRIBUTE}='JSON_STRINGIFIED_DATA' />`,
    );
  }
  return JSON.parse(initialStringifiedData) as T;
};
