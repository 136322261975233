import React, { forwardRef } from "react";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";

export type IconProps = FontAwesomeIconProps;
/**
 * Wrapper for FontAwesomeIcon
 * To keep bundle size small, only import the icons needed!
 * import { faHomeHeart } from '@fortawesome/pro-regular-svg-icons';
 * See https://fontawesome.com/how-to-use/on-the-web/using-with/react
 */
const Icon = forwardRef<SVGElement, IconProps>((props, ref) => {
  return <FontAwesomeIcon forwardedRef={ref} {...props} />;
});

export default Icon;
