import React, { ReactNode } from "react";
import cx from "classnames";

import { useTransition } from "common/hooks";
import { stopPropagation } from "common/utils";
import { StackableProps } from "common/stackable/types";

import { useBreakpoints } from "client/common/hooks";

import Overlay from "./Overlay";

import styles from "../../styles/Drawer.module.less";

export type DrawerProps = {
  children: ReactNode;
} & StackableProps;

const Drawer = ({
  isVisible,
  isFocused,
  stackIndex,
  visibleStackIndex,
  onClose,
  children,
}: DrawerProps) => {
  const { isTabletPortraitAndSmaller } = useBreakpoints();
  const transition = useTransition(isVisible);
  const { isMounted, transitionState, durationStyle } = transition;

  if (!isMounted) {
    return null;
  }

  const stackablePadding = isTabletPortraitAndSmaller
    ? { paddingTop: Math.min(stackIndex, 2) * 15 }
    : { paddingLeft: Math.min(stackIndex, 2) * 20 };

  return (
    <Overlay
      transition={transition}
      isVisible={visibleStackIndex === 0}
      isFocused={isFocused}
      onClose={onClose}
    >
      <div className={cx(styles.layout, isTabletPortraitAndSmaller && styles.compact)}>
        <div style={stackablePadding} className={styles.availableSpace}>
          <div
            className={cx(styles.container, styles[`transition-${transitionState}`])}
            style={{ transitionDuration: durationStyle }}
            onMouseDown={stopPropagation}
            onClick={stopPropagation}
            role="presentation"
          >
            <div className={styles.content}>{children}</div>
          </div>
        </div>
      </div>
    </Overlay>
  );
};

export default Drawer;
