import { useFetchQuery } from "common/queries/hooks";
import { Features } from "common/types";
import { useLoadStatus } from "common/load/hooks";

import { getInitialClientData } from "client/common/utils";

import { Clinic, StorefrontThemeColor } from "../types";
import { keys, urls } from "./constants";

/**
 * We preload initial data, and allow some retries when the data is stale to
 * not bring down the app if there is a temporary issue
 * if we still cannot retrieve the data, then we need to force a manual reload.
 *
 * Just setting `retryOnMount: false` is not working here, I'm assuming this may not
 * work if there was never a successful initial fetch.
 */
export const useClinicQuery = () => {
  const { forceIsStale, isStale } = useLoadStatus();
  return useFetchQuery<Clinic>(keys.activeClinicDetail(), {
    fetch: { url: urls.clinic() },
    config: {
      initialData: () => getInitialClientData().clinic,
      retryOnMount: false,
      retry: 4,
      onError: () => forceIsStale(),
      enabled: !isStale,
    },
  });
};

export const useClinic = () => {
  const { data: clinic } = useClinicQuery();

  if (!clinic) {
    // Since clinic is loaded from the initial data, we can assume it will never be null
    // if it is null for some reason, then something has gone very wrong and we should
    // throw an error to prevent the user from seeing a bad state and to alert engineering
    // that something needs to be fixed
    throw new Error("No clinic found");
  }

  return clinic;
};

export const useFeatures = (): Features => {
  const { features } = useClinic();
  return features;
};

export const useStorefrontThemeColorsQuery = ({ enabled = true }: { enabled?: boolean } = {}) => {
  return useFetchQuery<StorefrontThemeColor[]>(keys.storefrontThemeColorList(), {
    fetch: { url: urls.storefrontThemeColors() },
    config: { enabled },
  });
};
