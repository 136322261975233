import React, { ReactNode } from "react";
import cx from "classnames";

import LinkButton, { LinkButtonProps } from "common/components/LinkButton";
import Spinner from "common/components/Spinner";

import styles from "../../styles/PillButton.module.less";

type PillButtonProps = LinkButtonProps & {
  variant?: "primary" | "secondary" | "tertiary";
  isLoading?: boolean;
  /**
   * How much space in the UI the button should take up.
   */
  size?: "xs" | "s" | "m";
  /** inline content to act as the label */
  children: ReactNode;
};

/**
 * Block interactive button or link with rounded corner that grows with its content.
 * @param isLoading if true displays a loading indicator which replaces `children`
 * @param variant skin to apply to button:
 *   - `primary`: tracks the primary app color
 *   - `secondary`: tracks app control styles
 */
const PillButton = ({
  variant = "primary",
  isLoading,
  children,
  size = "m",
  className,
  disabled,
  ...rest
}: PillButtonProps) => {
  return (
    <LinkButton
      {...rest}
      // Loading and Disabled will have different styles, but will be treated the same functionally
      disabled={disabled || isLoading}
      className={cx(
        styles.container,
        styles[`variant-${variant}`],
        styles[`size-${size}`],
        isLoading && styles.isLoading,
        className,
      )}
    >
      <Spinner className={styles.spinner} />
      <span className={styles.label}>{children}</span>
    </LinkButton>
  );
};

export default PillButton;
