import { useContext, useMemo } from "react";

import AuthenticationContext, { AuthenticationContextValue } from "./AuthenticationContext";

/** Returns current authorization context */
export const useAuthentication = (): AuthenticationContextValue => {
  const { isAuthenticated, onUnauthenticated } =
    useContext<AuthenticationContextValue>(AuthenticationContext);
  return useMemo(
    () => ({ isAuthenticated, onUnauthenticated }),
    [isAuthenticated, onUnauthenticated],
  );
};
