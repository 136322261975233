import { useContext } from "react";

import CurrentStackableContext from "./CurrentStackableContext";
import StackableContext from "./StackableContext";

export const useStackables = () => {
  return useContext(StackableContext);
};

export const useCurrentStackable = (opts: { type: string }) => {
  const { type, remove, isVisible, isPositioned } = useContext(CurrentStackableContext);
  return {
    close: () => {
      if (type !== opts.type) {
        console.warn("Close not called from a modal context");
      } else {
        remove();
      }
    },
    isVisible,
    isPositioned: isVisible ? isPositioned : true,
  };
};
