import React from "react";

const noop = () => null;

type SearchContextValue = {
  searchInputRef: React.RefObject<HTMLInputElement>;
  focusSearchInput: () => void;
  shouldFocus: boolean;
  setShouldFocus: (autoFocus: boolean) => void;
};

const SearchContext = React.createContext<SearchContextValue>({
  searchInputRef: { current: null },
  focusSearchInput: noop,
  shouldFocus: false,
  setShouldFocus: noop,
});

export default SearchContext;
