import { QueryClient, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

import { useAuthentication } from "common/auth/hooks";
import { FetchError } from "common/fetch/errors";
import {
  ProfileDetail,
  IAddress,
  IClientUpdateFields,
  IPatient,
  IPatientWrite,
  PatientBreed,
  IPatientWriteBody,
  PatientColor,
  IClientProfileUpdate,
} from "common/types";
import {
  AddressConfirmMutation,
  AddressCreateMutation,
  IAddressFormValues,
  IVerifyAddress,
} from "common/types/addresses";
import { useFetchMutation, useFetchQuery } from "common/queries/hooks";
import { Optional } from "common/utils/types";

import {
  LoginErrorData,
  IClient,
  IProfile,
  IPasswordLogin,
  PasswordChangeFormValues,
  ResetPasswordConfirm,
  ResetPasswordRequest,
  IProfileLookupResult,
  IProfileLookup,
  IVerifySmsCodeSignup,
  IVerifySmsCodeSignupResult,
  ILoginPhoneValidationResults,
  LoginPhoneValidationSearch,
  ISendVerificationCode,
  PrimaryPhoneMutation,
  PrimaryPhone,
  PatientDetail,
  PatientUpdate,
  PatientUpdateBody,
} from "client/accounts/types";
import { urls as routeUrls } from "client/routes";

import { keys, urls } from "./constants";
import { useInvalidateClient } from "./invalidate";
import { patientWriteToPatientUpdateBody } from "../utils";

const noop = () => undefined;

export const setClientData = (queryClient: QueryClient, client: IClient) => {
  queryClient.setQueryData(keys.activeClientDetail(), client);
};

export const updateClientData = (
  queryClient: QueryClient,
  updates: Partial<IClient> | ((client: IClient) => IClient),
) => {
  const current = queryClient.getQueryData<IClient>(keys.activeClientDetail());
  if (!current) {
    return;
  }
  const updateFn =
    typeof updates === "function" ? updates : (client: IClient) => ({ ...client, ...updates });
  setClientData(queryClient, updateFn(current));
};

export const useClientQuery = () => {
  const { isAuthenticated } = useAuthentication();
  return useFetchQuery<IClient>(keys.activeClientDetail(), {
    fetch: { url: urls.client() },
    config: { enabled: isAuthenticated },
  });
};

export const useUpdateClientMutation = ({ onSuccess = noop }: { onSuccess?: () => void } = {}) => {
  const queryClient = useQueryClient();
  return useFetchMutation<IClientUpdateFields, IClient>({
    fetch: (body) => ({ method: "PATCH", url: urls.client(), body }),
    config: {
      onSuccess: (client) => {
        setClientData(queryClient, client);
        onSuccess();
      },
    },
  });
};

export const useUpdatePasswordMutation = ({
  onSuccess = noop,
}: { onSuccess?: () => void } = {}) => {
  return useFetchMutation<PasswordChangeFormValues, IProfile>({
    fetch: (body) => ({ method: "PUT", url: urls.updatePassword(), body }),
    config: {
      onSuccess,
    },
  });
};

export const useRequestResetPasswordLink = ({
  onSuccess,
}: {
  onSuccess: (response: ResetPasswordConfirm) => void;
}) => {
  return useFetchMutation<ResetPasswordRequest, ResetPasswordConfirm>({
    fetch: (body) => ({ method: "POST", url: urls.resetPassword(), body }),
    config: {
      onSuccess,
    },
  });
};

export const useCreateAddressMutation = ({
  onSuccess,
}: {
  onSuccess: (address: IAddress) => void;
}) => {
  const queryClient = useQueryClient();
  return useFetchMutation<AddressCreateMutation, IAddress>({
    fetch: (body) => ({ method: "POST", url: urls.addresses(), body }),
    config: {
      onSuccess: (data) => {
        updateClientData(queryClient, (client) => ({
          ...client,
          addresses: [
            ...client.addresses,
            {
              ...data,
              paused_subscription_count: 0,
              active_subscription_count: 0,
            },
          ],
        }));
        queryClient.invalidateQueries(keys.activeClientDetail());
        onSuccess(data);
      },
    },
  });
};

export const useDeleteAddressMutation = (
  addressId: number,
  { onSuccess = noop }: { onSuccess?: () => void } = {},
) => {
  const queryClient = useQueryClient();
  return useFetchMutation<void, IAddress>({
    fetch: () => ({ method: "DELETE", url: urls.address(addressId) }),
    config: {
      onSuccess: () => {
        updateClientData(queryClient, (current) => ({
          ...current,
          addresses: current.addresses.filter((a) => a.id !== addressId),
        }));
        queryClient.invalidateQueries(keys.activeClientDetail());
        onSuccess();
      },
    },
  });
};

export const useConfirmAddressMutation = (
  addressId: number,
  { onSuccess }: { onSuccess: () => void },
) => {
  return useFetchMutation<void, IAddress, IAddress, AddressConfirmMutation>({
    fetch: () => ({
      method: "PATCH",
      url: urls.address(addressId),
      body: { is_user_confirmed: true },
    }),
    config: {
      onSuccess,
    },
  });
};

export const useVerifyAddressMutation = ({
  onSuccess,
}: {
  onSuccess: (data: IVerifyAddress) => void;
}) => {
  return useFetchMutation<IAddressFormValues, IVerifyAddress>({
    fetch: (body) => ({ method: "POST", url: urls.addressVerify(), body }),
    config: {
      onSuccess,
    },
  });
};

export const useCreatePatientMutation = ({
  onSuccess = noop,
}: { onSuccess?: (patient: IPatient) => void } = {}) => {
  const queryClient = useQueryClient();
  return useFetchMutation<IPatientWrite, IPatient, IPatient, IPatientWriteBody>({
    fetch: (body) => ({
      method: "POST",
      url: urls.patients(),
      body: {
        ...body,
        primary_breed: body.primary_breed?.value ?? "",
        secondary_breeds: body.secondary_breeds.map((b) => b.value),
        color: body.color?.value ?? "",
      },
    }),
    config: {
      onSuccess: (patient) => {
        updateClientData(queryClient, (current) => ({
          ...current,
          // image is always null on create
          patients: [...current.patients, patient].map((p) => ({ ...p, image: null })),
        }));
        queryClient.invalidateQueries(keys.activeClientDetail());
        onSuccess(patient);
      },
    },
  });
};

export const useUpdatePatientMutation = (
  patientId: number,
  { onSuccess = noop }: { onSuccess?: () => void } = {},
) => {
  const queryClient = useQueryClient();
  return useFetchMutation<
    Partial<PatientUpdate>,
    PatientDetail,
    PatientDetail,
    Partial<PatientUpdateBody>
  >({
    fetch: (body) => ({
      url: urls.patient(patientId),
      body: patientWriteToPatientUpdateBody(body),
      method: "PATCH",
    }),
    config: {
      onSuccess: (data) => {
        updateClientData(queryClient, (current) => ({
          ...current,
          patients: current.patients.map((p) =>
            p.id === patientId ? { ...data, image: p.image } : p,
          ),
        }));
        queryClient.invalidateQueries(keys.activeClientDetail());
        queryClient.invalidateQueries(keys.patients());
        onSuccess();
      },
    },
  });
};

export const usePatientQuery = (id: Optional<number>) => {
  return useFetchQuery<IPatient>(keys.patientDetail(id ?? 0), {
    fetch: { url: urls.patient(id ?? 0) },
    config: { enabled: !!id },
  });
};

export const usePatientBreedsQuery = () => {
  return useFetchQuery<PatientBreed[]>(keys.patientBreedsList(), {
    fetch: { url: urls.patientBreeds() },
  });
};

export const usePatientColorsQuery = () => {
  return useFetchQuery<PatientColor[]>(keys.patientColorsList(), {
    fetch: { url: urls.patientColors() },
  });
};

export const useUpdateProfileMutation = ({ onSuccess = noop }: { onSuccess?: () => void } = {}) => {
  const queryClient = useQueryClient();
  return useFetchMutation<Partial<IClientProfileUpdate>, ProfileDetail>({
    fetch: (body) => ({ method: "PATCH", url: urls.profile(), body }),

    config: {
      onSuccess: (profile) => {
        // need to update profile before passing to success for refill flow step management
        // see https://github.com/vetcove/pharmacy-app/blob/92835633dc624abcb3cc7dcd05eaaa51243ca609/app/static/react/client/refills/utils/refillRequests.ts#L34
        updateClientData(queryClient, { user: profile });
        queryClient.invalidateQueries(keys.activeClientDetail());
        onSuccess();
      },
    },
  });
};

/**
 * Logs the user in and hard redirects to the url specified by `next`.
 * The hard redirect is required to clear any global context or react-query
 * `initialData` tied to the user context (including the CSRF token)
 */
export const usePasswordLoginMutation = (next: string) => {
  // special loading state is tracked to ensure that consumers still display
  // the mutation as loading while the redirect is in progress
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const mutation = useFetchMutation<
    IPasswordLogin,
    IProfile,
    void,
    IPasswordLogin,
    FetchError<void, LoginErrorData>
  >({
    fetch: (body) => ({ method: "POST", url: urls.login(), body }),
    config: {
      onMutate: () => setIsLoggingIn(true),
      onSuccess: () => window.location.replace(next),
      onError: () => setIsLoggingIn(false),
    },
  });
  return { ...mutation, isLoading: mutation.isLoading || isLoggingIn };
};

export const useProfileLookupMutation = ({
  onSuccess = noop,
}: {
  onSuccess?: (result: IProfileLookupResult) => void;
} = {}) => {
  return useFetchMutation<IProfileLookup, IProfileLookupResult>({
    fetch: (body: IProfileLookup) => ({
      method: "POST",
      url: urls.profileLookup(),
      body,
    }),
    config: {
      onSuccess,
    },
  });
};

export const useSendVerificationCodeMutation = ({
  onSuccess = noop,
  onError = noop,
}: {
  onSuccess?: () => void;
  onError?: () => void;
} = {}) => {
  return useFetchMutation<ISendVerificationCode, void>({
    fetch: (body) => ({
      method: "POST",
      url: urls.sendVerificationCode(),
      body,
    }),
    config: { onSuccess, onError },
  });
};

export const usePrimaryPhoneMutation = ({ onSuccess = noop }: { onSuccess?: () => void }) => {
  const invalidateClient = useInvalidateClient();

  return useFetchMutation<PrimaryPhoneMutation, PrimaryPhone>({
    fetch: (body) => ({ method: "POST", url: urls.primaryPhone(), body }),
    config: {
      onSuccess: () => {
        invalidateClient();
        onSuccess();
      },
    },
  });
};

/**
 * Logs the user in with an SMS code and hard redirects
 * to the url specified by `next`. The hard redirect is required
 * to clear any global context or react-query `initialData`
 * tied to the user context (including the CSRF token)
 */
export const useSmsLoginMutation = (next: string = routeUrls.home.root.to()) => {
  // special loading state is tracked to ensure that consumers still display
  // the mutation as loading while the redirect is in progress
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const mutation = useFetchMutation<IVerifySmsCodeSignup, IVerifySmsCodeSignupResult, void>({
    fetch: (body) => ({ method: "POST", url: urls.smsLogin(), body }),
    config: {
      onMutate: () => setIsLoggingIn(true),
      onSuccess: () => window.location.replace(next),
      onError: () => setIsLoggingIn(false),
    },
  });
  return { ...mutation, isLoading: mutation.isLoading || isLoggingIn };
};

export const useLoginPhoneNumberValidateQuery = (
  search: LoginPhoneValidationSearch,
  { enabled = true }: { enabled?: boolean } = {},
) => {
  return useFetchQuery<ILoginPhoneValidationResults, LoginPhoneValidationSearch>(
    keys.loginPhoneNumberValidation(search),
    {
      fetch: {
        url: urls.verifyLoginPhoneNumber(),
        body: search,
      },
      config: { enabled },
    },
  );
};
