import { lazy } from "common/load/componentUtils";

import { useDrawer } from "client/common/hooks/stackables";

const CartDrawer = lazy(() => import("../components/CartDrawer"));

export const useCartDrawer = () => {
  const { open } = useDrawer();
  return {
    open: () => open(CartDrawer, {}),
  };
};
