import {
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faInfoCircle,
} from "@fortawesome/pro-regular-svg-icons";

const alertIcons = {
  info: faInfoCircle,
  success: faCheckCircle,
  warning: faExclamationCircle,
  danger: faExclamationTriangle,
};

export default alertIcons;
