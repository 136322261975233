import React, { ReactNode } from "react";
import cx from "classnames";

import styles from "../../styles/TextChip.module.less";

type SizeProps =
  | { isInline?: false | undefined; size?: "s" | "m" }
  | { isInline: true; size?: never };

export type TextChipProps = {
  variant?:
    | "default"
    | "grey"
    | "primary"
    | "warning"
    | "promotion"
    | "promotion-fill"
    | "highlight"
    | "info"
    | "transparent";
  /**
   * if component is considered inline, it's size is cropped to to fit into the current line,
   * otherwise it's size is determined by `size`.
   *  */
  isInline?: boolean;
  isMultiline?: boolean;
  children: ReactNode;
  className?: string;
} & SizeProps;

/**
 * Inline text with background with a slightly smaller font size than its surrounding
 * content.
 */
const TextChip = ({
  children,
  isInline = false,
  isMultiline = false,
  size = "m",
  variant = "default",
  className = "",
}: TextChipProps) => {
  return (
    <span
      className={cx(
        styles.chip,
        styles[`variant-${variant}`],
        styles[`size-${size}`],
        isInline && styles.inline,
        isMultiline && styles.multiline,
        className,
      )}
    >
      {children}
    </span>
  );
};

export default TextChip;
