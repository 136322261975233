import React, { ErrorInfo, ReactNode, useCallback, useEffect, useMemo, useState } from "react";

import LoadStatusContext from "./LoadStatusContext";
import { STALE_LOAD_MS } from "./constants";

export type LoadStatusProviderProps = {
  /** The content to display in normal operating conditions */
  children: ReactNode;
  /**
   * Content to display if there is a critical load error,
   * such as an unrecoverable error loading js or css chunks
   * */
  fallback: ReactNode;
  /** The content to display to prompt the user to reload the the session */
  renderReloadRequiredOverlay: (renderProps: { isVisible: boolean }) => ReactNode;
};

/**
 * Will display an error page if we detect a connection problem for a critical error
 * We use the `window` here as the real "provider" for callbacks so that we can access it
 * when we are loading chunks / async scripts at js-boot-time (not at react-mount-time).
 *
 * Will additionally track if the currently loaded page is stale or not based on STALE_LOAD_MS.
 * If so, we will will perform hard refreshes on navigation.
 *
 * This should generally be as high as possible in the applications main react tree, but before
 * any final global error handling.
 *
 * @see {@link load/hooks!useLoadStatus} for consuming in general application code
 */
const LoadStatusProvider = ({
  children,
  fallback,
  renderReloadRequiredOverlay,
}: LoadStatusProviderProps) => {
  const [isStale, setIsStale] = useState(false);
  const [requiresManualReload, setRequiresManualReload] = useState(false);

  const forceIsStale = useCallback(() => setIsStale(true), []);

  const onRequiresManualReload = useCallback(() => {
    setRequiresManualReload(true);
    // if we require a manual reload we can assume our currently loaded instance is stale
    setIsStale(true);
  }, []);

  useEffect(() => {
    const isStaleTimeout = setTimeout(() => setIsStale(true), STALE_LOAD_MS);
    // as a last resort, if staleness is not caught by application state, show reload overlay
    const manualReloadTimeout = setTimeout(onRequiresManualReload, STALE_LOAD_MS * 2);
    return () => {
      clearTimeout(isStaleTimeout);
      clearTimeout(manualReloadTimeout);
    };
    // only start timeouts on mount, because that should be a
    // decent proxy for when the page initially loaded
  }, [onRequiresManualReload]);

  const [hasCriticalLoadError, setHasCriticalLoadError] = useState(false);

  const onCriticalLoadError = useCallback(
    (error?: Error, info?: ErrorInfo) => {
      setHasCriticalLoadError(true);
      console.warn("There was a critical load error:\n", error, info?.componentStack);
      console.trace();
    },
    [setHasCriticalLoadError],
  );

  useEffect(() => {
    // we need this on the window so it can be trigged by webpack-retry-chunk-load-plugin
    window.onCriticalLoadError = onCriticalLoadError;
    // eslint-disable-next-line no-underscore-dangle
    if (window._hasCriticalLoadError) {
      setHasCriticalLoadError(true);
    }
  }, [onCriticalLoadError]);

  const providerValue = useMemo(
    () => ({ isStale, onRequiresManualReload, requiresManualReload, forceIsStale }),
    [isStale, onRequiresManualReload, requiresManualReload, forceIsStale],
  );

  return (
    <LoadStatusContext.Provider value={providerValue}>
      {!hasCriticalLoadError ? children : fallback}
      {renderReloadRequiredOverlay({ isVisible: requiresManualReload })}
    </LoadStatusContext.Provider>
  );
};

export default LoadStatusProvider;
