import React from "react";

import Page from "./Page";

type EmptyPageProps = { children?: React.ReactNode };

const EmptyPage = ({ children }: EmptyPageProps) => {
  return (
    <Page Header={null} Footer={null}>
      {children}
    </Page>
  );
};

export default EmptyPage;
