import React, { ComponentType } from "react";

import { useCurrentStackable, useStackables } from "common/stackable/hooks";
import { lazy } from "common/load/componentUtils";

import DrawerComponent from "../components/stackables/Drawer";
import DialogComponent from "../components/stackables/Dialog";
import LightboxComponent from "../components/stackables/Lightbox";
import type { VideoLightboxProps } from "../components/content/VideoLightbox";

const VideoLightbox = lazy(() => import("../components/content/VideoLightbox"));

export type CloseProp = {
  onClose?: () => void;
};

enum StackableType {
  Drawer = "drawer",
  Dialog = "dialog",
  Lightbox = "lightbox",
}

export const useDrawer = () => {
  const { push, removeAll } = useStackables();
  return {
    open: (Component: ComponentType<object>, componentProps: object & CloseProp = {}) => {
      const { onClose, ...props } = componentProps;
      push({
        type: StackableType.Drawer,
        render: (stackableProps) => {
          return (
            <DrawerComponent {...stackableProps}>
              <Component {...props} />
            </DrawerComponent>
          );
        },
        onRemove: onClose,
      });
    },
    closeAll: () => removeAll(StackableType.Drawer),
  };
};

export const useCurrentDrawer = () => {
  return useCurrentStackable({ type: StackableType.Drawer });
};

export type DialogOpts = {
  onClose?: () => void;
};

export const useDialog = () => {
  const { push, removeAll } = useStackables();
  return {
    open: (Component: ComponentType<object>, componentProps: object & CloseProp = {}) => {
      const { onClose, ...props } = componentProps;
      push({
        type: StackableType.Dialog,
        render: (stackableProps) => {
          return (
            <DialogComponent {...stackableProps}>
              <Component {...props} />
            </DialogComponent>
          );
        },
        onRemove: onClose,
      });
    },
    closeAll: () => removeAll(StackableType.Dialog),
  };
};

export const useCurrentDialog = () => {
  return useCurrentStackable({ type: StackableType.Dialog });
};

export const useLightbox = () => {
  const { push, removeAll } = useStackables();
  return {
    open: (Component: ComponentType<object>, componentProps: object = {}) =>
      push({
        type: StackableType.Lightbox,
        render: (stackableProps) => {
          return (
            <LightboxComponent {...stackableProps}>
              <Component {...componentProps} />
            </LightboxComponent>
          );
        },
      }),
    closeAll: () => removeAll(StackableType.Lightbox),
  };
};

export const useCurrentLightbox = () => {
  return useCurrentStackable({ type: StackableType.Lightbox });
};

export const useVideoLightbox = () => {
  const { open } = useLightbox();
  return {
    open: (props: VideoLightboxProps) => open(VideoLightbox, props),
  };
};
