import React, { ReactNode } from "react";
import cx from "classnames";

import DefaultHeader from "../header/Header";
import DefaultFooter from "../footers/Footer";

import styles from "../../styles/Page.module.less";

type PageProps = {
  children: ReactNode;
  Header?: ReactNode;
  Footer?: ReactNode;
  className?: string;
  style?: React.CSSProperties;
};

const Page = ({
  children,
  Header = <DefaultHeader />,
  Footer = <DefaultFooter />,
  className,
  style,
}: PageProps) => {
  return (
    <div style={style} className={cx(styles.container, className)}>
      {Header}
      {children}
      {Footer}
    </div>
  );
};

export default Page;
