import { useQueryClient } from "@tanstack/react-query";

import { useFetchMutation, useFetchQuery } from "common/queries/hooks";

import { AppAlert } from "../types";
import { keys, urls } from "./constants";

export const useAlertsQuery = () => {
  return useFetchQuery<AppAlert[]>(keys.alertsList(), { fetch: { url: urls.alerts() } });
};

export const useAlertDeleteMutation = (id: number) => {
  const queryClient = useQueryClient();
  return useFetchMutation<void, void>({
    fetch: () => ({ method: "DELETE", url: urls.alert(id) }),
    config: {
      onMutate: async () => {
        await queryClient.cancelQueries(keys.alerts());
        queryClient.setQueryData<AppAlert[] | undefined>(
          keys.alertsList(),
          (alerts) => alerts && alerts.filter((alert) => !(alert.id === id)),
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries(keys.alerts());
      },
    },
  });
};
