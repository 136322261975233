import React, { ReactNode } from "react";
import cx from "classnames";

import { useTransition } from "common/hooks";
import { stopPropagation } from "common/utils";
import { StackableProps } from "common/stackable/types";

import Overlay from "client/common/components/stackables/Overlay";

import styles from "../../styles/Dialog.module.less";

type DialogProps = {
  children?: React.ReactNode;
  isStandalone?: boolean;
} & StackableProps;

const Dialog = ({ children, isFocused, isVisible, onClose, isStandalone = false }: DialogProps) => {
  const transition = useTransition(isVisible);
  const { isMounted, transitionState, durationStyle } = transition;

  if (!isMounted) {
    return null;
  }

  return (
    <Overlay
      isFocused={isFocused}
      isVisible={isVisible}
      onClose={onClose}
      transition={transition}
      className={cx(styles.container, isStandalone && styles.standalone)}
    >
      <div
        className={cx(styles.box, styles[`transition-${transitionState}`])}
        onMouseDown={stopPropagation}
        onClick={stopPropagation}
        role="presentation"
        style={{
          transitionDuration: durationStyle,
        }}
      >
        {children}
      </div>
    </Overlay>
  );
};

type StandaloneDialogProps = {
  isVisible: boolean;
  children: ReactNode;
  onClose: () => void;
};

/**
 * A version of the dialog decoupled from the stackable infrastructure, to be used
 * in contexts that don't have access to the stackable provider. You should almost always
 * be using a hook and should rarely have to use this component.
 */
const StandaloneDialog = ({ isVisible, children, onClose }: StandaloneDialogProps) => {
  return (
    <Dialog
      isVisible={isVisible}
      onClose={onClose}
      stackIndex={0}
      // this currently won't play nice with stackables, we may want to consider
      // a context that takes ownership of the current focused element from stackables
      // if we mount this component
      isFocused={true}
      visibleStackIndex={0}
      isStandalone={true}
    >
      {children}
    </Dialog>
  );
};

export default Object.assign(Dialog, { Standalone: StandaloneDialog });
