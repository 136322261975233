export const urls = {
  // profile
  profile: () => "/api/client/v1/accounts/profile/",
  profileLookup: () => "/api/client/v1/accounts/lookup/",
  // password management
  completeSignup: () => "/api/client/v1/accounts/profile/complete-signup/",
  updatePassword: () => "/api/client/v1/accounts/profile/update-password/",
  resetPassword: () => "/api/client/v1/accounts/reset-password/",
  // login
  login: () => "/api/client/v1/accounts/signin/",
  smsLogin: () => "/api/client/v1/accounts/verify-sign-up/",
  sendVerificationCode: () => "/api/client/v1/accounts/send-verification-code/",
  // validation
  verifyLoginPhoneNumber: () => "/api/client/v1/accounts/signin-phone-numbers/verify/",
  // Phone
  primaryPhone: () => "/api/client/v1/accounts/primary-phone/",
  // client
  client: () => "/api/client/v1/clients/client/",
  // addresses
  addresses: () => "/api/client/v1/accounts/addresses/",
  address: (id: number) => `/api/client/v1/accounts/addresses/${id}/`,
  addressVerify: () => "/api/client/v1/accounts/addresses/verify/",
  // patients
  patients: () => "/api/client/v1/clients/patients/",
  patient: (id: number) => `/api/client/v1/clients/patients/${id}/`,
  notificationUser: () => "/api/client/v1/communications/notification-user/",
  patientBreeds: () => `/api/client/v1/clients/patients/breeds/`,
  patientColors: () => `/api/client/v1/clients/patients/colors/`,
};

export const keys = {
  /** all queries in the accounts namespace */
  all: () => ["accounts-all"],
  // clients
  clients: () => [...keys.all(), "clients"],
  clientDetails: () => [...keys.clients(), "detail"],
  activeClientDetail: () => [...keys.clientDetails(), "active"],
  // patients
  patients: () => [...keys.all(), "patients"],
  patientDetails: () => [...keys.patients(), "detail"],
  patientDetail: (id: number) => [...keys.patientDetails(), id],
  // patient breeds
  patientBreeds: () => [...keys.all(), "breeds"],
  patientBreedsLists: () => [...keys.all(), "list"],
  patientBreedsList: () => [...keys.patientBreedsLists()],
  // patient colors
  patientColors: () => [...keys.all(), "colors"],
  patientColorsLists: () => [...keys.all(), "list"],
  patientColorsList: () => [...keys.patientColorsLists()],
  // validation
  validation: () => [...keys.all(), "validation"],
  loginPhoneNumberValidations: () => [...keys.validation(), "login-phone-number"],
  loginPhoneNumberValidation: (opts: { number: string }) => [
    ...keys.loginPhoneNumberValidations(),
    opts,
  ],
};
