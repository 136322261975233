import { makeRoutes, MakeRoutesOpts } from "common/router/utils/routes";
import { MakePaths } from "common/router/types";
import { makeUrls } from "common/router/utils/urls";

const paths = {
  invalid: "/invalid",
};

type LinkPathDefinitions = {
  invalid: {};
};

export type LinkPaths = MakePaths<LinkPathDefinitions>;

export const useLinkRoutes = (opts: MakeRoutesOpts) => {
  return makeRoutes(opts, [
    { path: paths.invalid, element: () => import("../components/InvalidLinkPage") },
  ]);
};

export const makeLinkUrls = (base: string) => {
  return makeUrls<LinkPathDefinitions>(base, {
    invalid: { path: paths.invalid },
  });
};
