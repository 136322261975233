import { useRef } from "react";

interface OptionType<T> {
  /** If the mouse travels fewer than this number of pixels between polling intervals, then the onMouseOver callback will be called. With the minimum sensitivity threshold of 1, the mouse must not move between polling intervals. With higher sensitivity thresholds you are more likely to receive a false positive. */
  sensitivity?: number;
  /**
   * The number of milliseconds this waits between reading/comparing mouse coordinates. When the user's mouse first enters the element its coordinates are recorded. The soonest the onMouseOut callback can be called is after a single polling interval. Setting the polling interval higher will increase the delay before the first possible onMouseOver call, but also increases the time to the next point of comparison.
   */
  interval?: number;
  /**
   * A simple delay, in milliseconds, before the onMouseOut callback is fired. If the user mouses back over the element before the timeout has expired the onMouseOut callback will not be called (nor will the onMouseOver callback be called). This is primarily to protect against sloppy/human mousing trajectories that temporarily (and unintentionally) take the user off of the target element... giving them time to return.
   */
  timeout?: number;
  /**
   * Callback that is called when the mouse enters the element.
   */
  onHover: (e: React.MouseEvent<T>) => void;
}

/**
 * Simplified version of useHoverIntent from https://github.com/natelindev/react-use-hoverintent
 */
export const useHoverIntent = <T>(options: OptionType<T>) => {
  const { sensitivity = 6, interval = 100, timeout = 0, onHover } = options ?? {};

  const valuesRef = useRef({
    isActivated: false,
    x: 0,
    y: 0,
    pX: 0,
    pY: 0,
    timer: 0,
  });

  const hover = (e: React.MouseEvent<T>) => {
    if (valuesRef.current.isActivated) {
      return;
    }

    valuesRef.current.isActivated = true;
    onHover(e);
  };

  const delay = () => {
    const { timer } = valuesRef.current;

    if (timer) {
      clearTimeout(timer);
    }
  };

  const tracker = (e: React.MouseEvent<T>) => {
    valuesRef.current.x = e.clientX;
    valuesRef.current.y = e.clientY;
  };

  const compare = (e: React.MouseEvent<T>) => {
    const { x, y, pX, pY, timer } = valuesRef.current;

    if (timer) {
      clearTimeout(timer);
    }

    if (Math.abs(pX - x) + Math.abs(pY - y) < sensitivity) {
      hover(e);
      return;
    }

    valuesRef.current.pX = x;
    valuesRef.current.pY = y;
    valuesRef.current.timer = window.setTimeout(() => compare(e), interval);
  };

  const dispatchOver = (e: React.MouseEvent<T>) => {
    const { timer, isActivated } = valuesRef.current;

    if (timer) {
      clearTimeout(timer);
    }

    if (!isActivated) {
      valuesRef.current.pX = e.clientX;
      valuesRef.current.pY = e.clientY;

      valuesRef.current.timer = window.setTimeout(() => compare(e), interval);
    }
  };
  const dispatchOut = () => {
    const { timer, isActivated } = valuesRef.current;

    if (timer) {
      clearTimeout(timer);
    }

    if (isActivated) {
      valuesRef.current.timer = window.setTimeout(() => delay(), timeout);
    }
  };

  const getReferenceProps = (referenceProps: React.HTMLProps<T>) => {
    const onMouseOver = (e: React.MouseEvent<T>) => {
      referenceProps.onMouseOver?.(e);
      dispatchOver(e);
    };

    const onMouseOut = (e: React.MouseEvent<T>) => {
      referenceProps.onMouseOut?.(e);
      dispatchOut();
    };

    const onMouseMove = (e: React.MouseEvent<T>) => {
      referenceProps.onMouseMove?.(e);
      tracker(e);
    };

    return {
      ...referenceProps,
      onMouseOver,
      onMouseMove,
      onMouseOut,
    };
  };

  return { getReferenceProps };
};
