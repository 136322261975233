import { MakePaths } from "common/router/types";
import { makeRoutes, MakeRoutesOpts } from "common/router/utils/routes";
import { makeUrls } from "common/router/utils/urls";

import { SubscriptionSearch } from "../types";

const paths = {
  list: "/",
  detail: "/:id",
};

type SubscriptionParams = {
  id: number;
};

type SubscriptionPathDefinitions = {
  list: {};
  detail: { Params: SubscriptionParams; Search: SubscriptionSearch };
};

export type SubscriptionPaths = MakePaths<SubscriptionPathDefinitions>;

export const useSubscriptionRoutes = (opts: MakeRoutesOpts) => {
  return makeRoutes(opts, [
    { path: paths.list, element: () => import("../components/SubscriptionsPage") },
    { path: paths.detail, element: () => import("../components/SubscriptionPage") },
  ]);
};

export const makeSubscriptionUrls = (base: string) => {
  return makeUrls<SubscriptionPathDefinitions>(base, {
    list: { path: paths.list },
    detail: { path: paths.detail, to: ({ id }) => `/${id}` },
  });
};
