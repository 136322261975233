import { MouseEvent } from "react";

import { Optional } from "./types";

export const stopPropagation = (e: MouseEvent) => e.stopPropagation();

/** Utils for managing/preventing scrolling on the body  */
export const bodyScroll = {
  prevent: () => {
    const scrolBarWidth = window.innerWidth - document.documentElement.clientWidth;
    // Add padding to make up for removing the scroll bar
    document.body.style.padding = `0 ${scrolBarWidth}px 0 0`;
    document.body.style.overflow = "hidden";
  },
  allow: () => {
    // Put it all back when we unmount
    document.body.style.removeProperty("padding");
    document.body.style.removeProperty("overflow");
  },
  isAlreadyPrevented: () => {
    return document.body.style.overflow === "hidden";
  },
};

/** Limits the call of a provided function to a specified length */
export const maxLengthCallback = (
  val: string,
  limit: Optional<number>,
  callback: (arg: string) => void,
) => {
  if (typeof limit !== "number" || val.length <= limit) {
    callback(val);
  }
};

/**
 * Returns true if value would display as meaningful data.
 */
export const isValueSet = <T>(value: T): value is NonNullable<T> => {
  // cast to any[] to allow value type check to be any type, not just those
  // defined in the array
  return !([undefined, null, ""] as any[]).includes(value);
};

export const isPercentSet = <T>(value: T): value is NonNullable<T> => {
  if (!isValueSet(value)) {
    return false;
  }
  if (typeof value === "string") {
    return !!parseFloat(value);
  }
  return !!(typeof value === "number" && value);
};
