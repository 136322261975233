import React, { ReactNode } from "react";

import { FetchError } from "common/fetch/types";
import { Optional } from "common/utils/types";

import Button from "../buttons/Button";
import FetchErrorAlert from "../errors/FetchErrorAlert";

import styles from "../../styles/ConfirmationDialog.module.less";

const noop = () => undefined;

type ConfirmationDialogProps<T extends FetchError> = {
  title: string;
  description: string;
  onConfirm: () => void;
  onDeny: () => void;
  confirmText?: string;
  denyText?: string;
  isLoading?: boolean;
  error?: Optional<T>;
  /** A custom render function for the error.
   * If nothing is returned or no method is provided, falls back to default rendering */
  renderError?: (error: Optional<T>) => Optional<ReactNode>;
  /** Any extra content to be displayed after the description, but before the actions */
  children?: ReactNode;
  hasDenyButton?: boolean;
};

const ConfirmationDialog = <T extends FetchError>({
  title,
  description,
  confirmText = "Confirm",
  denyText = "Cancel",
  onConfirm = noop,
  onDeny = noop,
  isLoading,
  error,
  renderError = noop,
  children,
  hasDenyButton = true,
}: ConfirmationDialogProps<T>) => {
  return (
    <div className={styles.content}>
      {error && (renderError(error) ?? <FetchErrorAlert error={error} />)}
      <div className={styles.text}>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.description}>{description}</p>
        <div>{children}</div>
      </div>
      {children}
      <div className={styles.actions}>
        {hasDenyButton && (
          <Button variant="secondary" onClick={onDeny}>
            {denyText}
          </Button>
        )}
        <Button onClick={onConfirm} isLoading={isLoading}>
          {confirmText}
        </Button>
      </div>
    </div>
  );
};

export default ConfirmationDialog;
