import React from "react";

import { FetchError } from "common/fetch/types";
import { Optional } from "common/utils/types";

import Alert, { ActionButton } from "../content/Alert";
import ErrorMessage from "./ErrorMessage";

export type FetchErrorAlertFieldErrors = Optional<(string[] | undefined)[]>;

export type FetchErrorAlertProps<T> = {
  error: Optional<FetchError<T>>;
  fieldErrors?: FetchErrorAlertFieldErrors;
  actions?: ActionButton[];
};

/**
 * Alert to display if we have detected a fetch error.
 */
const FetchErrorAlert = <T extends object>({
  error,
  fieldErrors = [],
  actions = [],
}: FetchErrorAlertProps<T>) => {
  // deduplicated errors combining `non_field_errors` and `fieldErrors`
  const displayFieldErrors = [
    ...new Set(
      [...(error?.errors?.non_field_errors ?? []), ...(fieldErrors ?? [])].flatMap(
        (fieldError) => fieldError ?? [],
      ),
    ),
  ];

  return (
    <div>
      <Alert variant="danger" actions={actions} shouldScrollOnMount={true}>
        {error?.detail}
      </Alert>
      {displayFieldErrors.map((fieldError) => (
        <ErrorMessage key={fieldError}>{fieldError}</ErrorMessage>
      ))}
    </div>
  );
};

export default FetchErrorAlert;
