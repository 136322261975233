export type LoadExternalScriptOpts = {
  /** A callback that allows for additional options
   * to be set on the script before it is appended to the page  */
  modifyScript?: (script: HTMLScriptElement) => HTMLScriptElement;
};

/**
 * Loads a 3rd party script that's not supported in the NPM ecosystem.
 * This is common for Google SDKs.
 *
 * @param src The source of the external script script
 * @param opts Any options to apply
 * @returns A Promise that resolves once the script has been loaded
 * with a boolean of whether the script was created or not
 */
export const loadExternalScript = (src: string, opts: LoadExternalScriptOpts = {}) => {
  return new Promise<boolean>((resolve, reject) => {
    const existingScript = document.querySelector<HTMLScriptElement>(`script[src='${src}']`);
    // If the script already exists, then we're done here
    if (existingScript) {
      resolve(false);
      return;
    }

    // Otherwise, create the script and resolve once it's been loaded in
    const script = document.createElement("script");
    script.src = src;
    script.async = true;

    const resolvedScript = opts.modifyScript ? opts.modifyScript(script) : script;
    resolvedScript.onload = () => resolve(true);
    resolvedScript.onerror = () => reject();
    document.body.appendChild(resolvedScript);
  });
};

/**
 * Loads a 3rd party CSS file
 * @param src The source of the CSS file
 * @returns A Promise that resolves once the CSS has been loaded
 * with a boolean of whether the CSS was created or not
 */
export const loadExternalCSS = (src: string) => {
  return new Promise<boolean>((resolve, reject) => {
    const existingCSS = document.querySelector<HTMLLinkElement>(`link[href='${src}']`);
    if (existingCSS) {
      resolve(false);
      return;
    }

    const css = document.createElement("link");
    css.href = src;
    css.rel = "stylesheet";
    css.onload = () => resolve(true);
    css.onerror = () => reject();
    document.body.appendChild(css);
  });
};

/**
 * Simple wrapper to load an async resource. It will console warn and return null
 * if there was some problem getting the resource.
 */
export const safeLoad = async <T extends any>(load: () => Promise<T>): Promise<T | null> => {
  try {
    return await load();
  } catch (e) {
    console.warn("There was a problem performing load");
    return null;
  }
};
