import { makeRoutes, MakeRoutesOpts } from "common/router/utils/routes";
import { MakePaths } from "common/router/types";
import { makeUrls } from "common/router/utils/urls";

import { AccountSearch, AuthSearch } from "../types";

const paths = {
  root: "/",
  signup: "/signup",
  emailSignup: "/email-signup",
  login: "/login",
  resetPassword: "/reset-password",
  lookup: "/lookup",
};

type AccountPathDefinitions = {
  detail: { Search: AccountSearch };
  signup: { Search: AuthSearch };
  emailSignup: { Search: AuthSearch };
  login: { Search: AuthSearch };
  resetPassword: { Search: AuthSearch };
  lookup: { Search: AuthSearch };
};

export type AccountPaths = MakePaths<AccountPathDefinitions>;

export const useAccountRoutes = (opts: MakeRoutesOpts) => {
  return makeRoutes(opts, [
    { path: paths.root, element: () => import("../components/AccountPage") },
    { path: paths.signup, element: () => import("../components/auth/CreateAccountPage") },
    { path: paths.emailSignup, element: () => import("../components/auth/CreateAccountEmailPage") },
    { path: paths.login, element: () => import("../components/auth/LoginPage") },
    { path: paths.resetPassword, element: () => import("../components/auth/ResetPasswordPage") },
    { path: paths.lookup, element: () => import("../components/auth/LookupPage") },
  ]);
};

export const makeAccountUrls = (base: string) => {
  return makeUrls<AccountPathDefinitions>(base, {
    detail: { path: paths.root },
    signup: { path: paths.signup },
    emailSignup: { path: paths.emailSignup },
    login: { path: paths.login },
    resetPassword: { path: paths.resetPassword },
    lookup: { path: paths.lookup },
  });
};
