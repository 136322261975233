export const urls = {
  alerts: () => "/api/client/v1/alerts/",
  alert: (id: number) => `/api/client/v1/alerts/${id}/`,
};

export const keys = {
  all: () => ["alerts-all"],
  // alerts
  alerts: () => [...keys.all(), "alerts"],
  alertsLists: () => [...keys.alerts(), "list"],
  alertsList: () => [...keys.alertsLists(), {}],
};
