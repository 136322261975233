import error404 from "../images/errors/error-404.svg";
import error500 from "../images/errors/error-500.svg";
import errorConnection from "../images/errors/error-connection.svg";
import emptyCart from "../images/errors/empty-cart.svg";
import emptyAutoships from "../images/errors/empty-autoships.svg";
import emptyOrders from "../images/errors/empty-orders.svg";
import emptyPrescriptions from "../images/errors/empty-prescriptions.svg";
import emptyResults from "../images/errors/empty-results.svg";
import emptyClients from "../images/errors/empty-clients.svg";
import emptyConversation from "../images/horse-with-letter.svg";
import emptyInbox from "../images/mailbox-with-bee.svg";
import emptyLetter from "../images/errors/empty-inbox.svg";
import emptyCalendar from "../images/errors/empty-calendar.svg";
import emptyPricing from "../images/errors/empty-pricing.svg";

const errorImages = {
  Error403: errorConnection,
  Error404: error404,
  Error500: error500,
  ErrorDefault: errorConnection,
  EmptyCart: emptyCart,
  EmptyAutoships: emptyAutoships,
  EmptyOrders: emptyOrders,
  EmptyPrescriptions: emptyPrescriptions,
  EmptyResults: emptyResults,
  EmptyClients: emptyClients,
  EmptyConversation: emptyConversation,
  EmptyInbox: emptyInbox,
  EmptyLetter: emptyLetter,
  EmptyCalendar: emptyCalendar,
  EmptyPricing: emptyPricing,
};

export default errorImages;
