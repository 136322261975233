import React from "react";

const ShoppingBag = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height="1.4em"
      width="1.4em"
      viewBox="0 0 31 31"
      {...props}
      style={{ position: "relative", top: 1 }}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M5 8.429L8.321 4h13.286l3.322 4.429M5 8.429v15.5c0 .587.233 1.15.649 1.565.415.416.978.649 1.565.649h15.5c.588 0 1.15-.233 1.566-.649.415-.415.649-.978.649-1.565v-15.5M5 8.429h19.929m-5.536 4.428c0 1.175-.467 2.301-1.297 3.132-.83.83-1.957 1.297-3.132 1.297-1.174 0-2.3-.467-3.131-1.297-.83-.83-1.297-1.957-1.297-3.132"
      />
    </svg>
  );
};

export default ShoppingBag;
