/* eslint-disable max-classes-per-file */
// this is a registery of errors that performFetch could raise, there is no reason
// to restrict it to one-class-per-file

import { Optional } from "common/utils/types";

import {
  FieldErrors,
  FetchError as FetchErrorType,
  StatusCode,
  FatalFetchErrorKind,
} from "./types";

/** {@inheritDoc fetch/types!FetchError} */
export class FetchError<T = object, D = object> extends Error implements FetchErrorType<T, D> {
  constructor(
    public status: number = StatusCode.InternalServerError,

    public detail: string = "Something went wrong.",
    public errors: FieldErrors<T> = {},
    public data: Optional<D> = null,
  ) {
    super(JSON.stringify(errors));
    this.status = status;
    this.detail = detail;
    this.errors = errors;
    this.data = data;
  }
}

/**
 * Fetch errors that are expected to be handled by fetch infrastructure, and tend to indicate
 * that something has gone wrong that can't be recovered from without user interaction, or a
 * page-reload.
 */
export class FatalFetchError<T = object> extends FetchError<T> {
  constructor(public kind: FatalFetchErrorKind, status: number, detail: string) {
    super(status, detail, {}, {});
  }
}
