import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons";
import React, { ReactNode, useEffect, useRef } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import cx from "classnames";

import Icon from "common/components/Icon";
import Spinner from "common/components/Spinner";

import alertIcons from "client/common/constants/alertIcons";

import TextButton from "../buttons/TextButton";

import styles from "../../styles/Alert.module.less";

type AlertVariant = "info" | "success" | "warning" | "danger";

const exhaustiveCheck = (variant: never) => {
  console.warn(`Attempted to get icon for invalid variant: ${variant}`);
  return faExclamationTriangle;
};

const getIcon = (variant: AlertVariant) => {
  switch (variant) {
    case "info":
      return alertIcons.info;
    case "success":
      return alertIcons.success;
    case "warning":
      return alertIcons.warning;
    case "danger":
      return alertIcons.danger;
    default:
      return exhaustiveCheck(variant);
  }
};

export type ActionButton = {
  label: string;
  onClick: () => void;
  isLoading?: boolean;
  hasRightIcon?: boolean;
};

type AlertProps = {
  icon?: IconProp;
  variant?: AlertVariant;
  children: ReactNode;
  actions?: ActionButton[];
  className?: string;
  shouldScrollOnMount?: boolean;
};

const Alert = ({
  variant = "info",
  icon = getIcon(variant),
  children,
  actions = [],
  className,
  shouldScrollOnMount = false,
}: AlertProps) => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (shouldScrollOnMount) {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }
  });

  return (
    <div className={cx(styles.container, styles[variant], className)} ref={ref}>
      <Icon icon={icon} className={styles.icon} />
      <div className={styles.content}>
        <div className={styles.description}>{children}</div>
        {!!actions.length && (
          <div className={styles.actions}>
            {actions.map(({ label, isLoading, onClick, hasRightIcon = false }) => (
              <TextButton
                className={styles.action}
                key={label}
                onClick={onClick}
                icon={hasRightIcon ? "right" : undefined}
              >
                {isLoading ? <Spinner /> : label}
              </TextButton>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Alert;
